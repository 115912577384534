import { TimeBoundTokenServicePayload } from "@model/SurveyModel";

export const sessionStorageIds = {
  TBT: "TBT",
  CONNECT: "connectToken",
  SURVEY_SUBMITTED_HERE: "surveySubmittedHere",
  PATIENT_ID: "patientId",
  USER_ID: "userId",
  TBT_PAYLOAD: "TBTPayload",
  PPI: "PPI",
};

export const cleanSessionStorage = () => {
  sessionStorage.removeItem(sessionStorageIds.TBT);
  sessionStorage.removeItem(sessionStorageIds.CONNECT);
  sessionStorage.removeItem(sessionStorageIds.SURVEY_SUBMITTED_HERE);
  sessionStorage.removeItem(sessionStorageIds.PATIENT_ID);
  sessionStorage.removeItem(sessionStorageIds.USER_ID);
  sessionStorage.removeItem(sessionStorageIds.TBT_PAYLOAD);
  sessionStorage.removeItem(sessionStorageIds.PPI);
};

export const getPayloadInSession = (): TimeBoundTokenServicePayload | null => {
  const storedPayload = sessionStorage.getItem(sessionStorageIds.TBT_PAYLOAD);

  if (storedPayload) {
    const storedPayloadJSONed = JSON.parse(storedPayload);
    return storedPayloadJSONed;
  }

  return null;
};

export const setPayloadInSession = (payload: TimeBoundTokenServicePayload) => {
  sessionStorage.setItem(
    sessionStorageIds.TBT_PAYLOAD,
    JSON.stringify(payload)
  );
};

export const getPPIInSession = (): number | null => {
  return parseInt(sessionStorage.getItem(sessionStorageIds.PPI));
};

export const setPPIInSession = (PPI: number) => {
  sessionStorage.setItem(sessionStorageIds.PPI, PPI.toString());
};
