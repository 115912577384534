import React, { memo, useEffect, useMemo, useState } from "react";
import style from "./style.module.scss";
import { createPortal } from "react-dom";
import { useTranslation } from "@hooks/useTranslation";
import LazyImage from "@components/common/LazyImage/LazyImage";
import IconCircleModal from "@components/common/IconCircleModal/component";
import { SELECTOR_RXR } from "@utility/utility";
import { printHTML } from "@utility/htmlUtility";
import Button from "@components/common/Button/component";

type GyroscopeManagementProps = {
  isOpen: boolean;
  nextAction: () => void;
};

const GyroscopeManagement = memo(
  ({ isOpen = false, nextAction }: GyroscopeManagementProps) => {
    const translate = useTranslation();
    const [gyroStep, setGyroStep] = useState<
      "info" | "request" | "are-you-sure"
    >("info");

    const checkGyroscopePermissionStatus = () => {
      const gyroscopePermissionStatus = localStorage.getItem(
        "gyroscopePermissionStatus"
      );
      switch (gyroscopePermissionStatus) {
        case "granted":
          nextAction();
          break;
        case "denied":
          setGyroStep("are-you-sure");
          break;
        // for debug purposes
        case "unknown":
          nextAction();
        default:
          setGyroStep("info");
      }
    };

    // Effettua il controllo quando il componente viene montato
    useEffect(() => {
      checkGyroscopePermissionStatus();
    }, []);

    const requestPermission = async () => {
      // Verifica se il browser richiede l'autorizzazione per l'orientamento
      setGyroStep("request");

      if (typeof DeviceOrientationEvent.requestPermission === "function") {
        try {
          const permission = await DeviceOrientationEvent.requestPermission();
          if (permission === "granted") {
            localStorage.setItem("gyroscopePermissionStatus", "granted");
          } else {
            localStorage.setItem("gyroscopePermissionStatus", "denied");
          }
        } catch (error) {
          console.error(
            "Error while requesting device orientation permission",
            error
          );
          localStorage.setItem("gyroscopePermissionStatus", "unknown");
        }
      } else {
        // Se il browser non richiede permessi (es. su desktop), considera permesso concesso
        console.log(
          "Device orientation permission is not required on this device/browser."
        );
        localStorage.setItem("gyroscopePermissionStatus", "unknown");
      }

      checkGyroscopePermissionStatus();
    };

    const handleRequestGyroscopePermission = async () => {
      await requestPermission();
    };

    return isOpen
      ? createPortal(
          <div className={`${style.modal}`}>
            {(() => {
              switch (gyroStep) {
                case "info":
                  return (
                    <>
                      <IconCircleModal className={style.iconCircleRing}>
                        <LazyImage
                          className={style.iconCircleCircle}
                          src={`${window.rxrEnv?.ASSETS_PATH}/icons/gyroscope_black.svg`}
                          alt="Gyroscope"
                          width="80px"
                          height="80px"
                        />
                      </IconCircleModal>
                      <p className={style.title}>
                        {translate("gyroscope_settings.info_title")}
                      </p>
                      <p className={style.message}>
                        {translate("gyroscope_settings.info_descr1")}
                      </p>
                      <Button
                        className={style.gyroscopeCTA}
                        variant={"light"}
                        onClick={handleRequestGyroscopePermission}
                      >
                        {translate("gyroscope_settings.tap_to_allow_label")}
                      </Button>
                    </>
                  );
                case "request":
                  return <></>;
                case "are-you-sure":
                  return (
                    <>
                      <IconCircleModal className={style.iconCircleRing}>
                        <LazyImage
                          className={style.iconCircleCircle}
                          src={`${window.rxrEnv?.ASSETS_PATH}/icons/gyroscope_black.svg`}
                          alt="Gyroscope"
                          width="80px"
                          height="80px"
                        />
                      </IconCircleModal>
                      <p className={style.title}>
                        {translate(
                          "gyroscope_settings.are_you_sure_no_gyro_title"
                        )}
                      </p>
                      <p
                        className={style.stepMessage}
                        dangerouslySetInnerHTML={printHTML(
                          translate(
                            "gyroscope_settings.are_you_sure_no_gyro_descr"
                          )
                        )}
                      />
                      <Button
                        className={style.gyroscopeCTA}
                        variant={"light"}
                        onClick={nextAction}
                      >
                        {translate("gyroscope_settings.continue_with_no_gyro")}
                      </Button>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>,
          document.querySelector(SELECTOR_RXR)
        )
      : null;
  }
);

export default GyroscopeManagement;
