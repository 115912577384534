import GeolocationButton from "@components/GeolocationButton/component";
import HowToReadPrescriptionButton from "@components/HowToReadPrescriptionButton/component";
import ModalQrCodeSurvey from "@components/ModalQrCodeSurvey/component";
import stylePageCardMobile from "@components/PageCardMobile/style.module.scss";
import PrescriptionForm, {
  PrescriptionData,
  getPrescriptionIds,
  sanitizePrescriptionValues,
} from "@components/PrescriptionForm/component";
import RecapData from "@components/RecapData/component";
import SelectList from "@components/SelectList/component";
import SelectPD, {
  getPupillaryDistanceFinalData,
} from "@components/SelectPD/component";
import SelectState from "@components/SelectState/component";
import SelectSurvey from "@components/SelectSurvey/component";
import SurveyBirthdayInput from "@components/SurveyBirthdayInput/component";
import Checkbox from "@components/common/Checkbox/component";
import OrSeparator from "@components/common/OrSeparator/component";
import PageStandard from "@components/common/PageStandard/component";
import { RadioInsideSelectProps } from "@components/common/SelectCustom/component";
import Sidebar from "@components/common/Sidebar/component";
import Spinner from "@components/common/Spinner/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import {
  PrescriptionType,
  QuestionnarieAnswer,
  StepType,
} from "@model/SurveyModel";
import { selectConfig } from "@slices/configSlice";
import {
  saveSurveyForConnectAndTBTS,
  selectCountryListInfo,
  selectSendAnswersInfo,
  selectSurvey,
  selectSurveyInfo,
  selectTBTInfo,
  selectUserAnswersForm,
  sendAnswers,
  surveyActions,
  surveyAsyncActions,
} from "@slices/surveySlice";
import { selectLabelInfo } from "@slices/utilitySlice";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";
import { printHTML, removeHTMLtags } from "@utility/htmlUtility";
import { ROUTING } from "@utility/routingUtility";
import { sessionStorageIds } from "@utility/sessionStorageUtility";
import {
  findStepInfoByUrlParam,
  numberOfFurtherSteps,
  prescriptionStepId,
  reviewStepId,
} from "@utility/surveyUtility";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import style from "./style.module.scss";

type Props = {};

const SurveyPage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const breakpoint = useBreakpointBoolean();
  const translate = useTranslation();
  const isLabelLoading = useSelector(selectLabelInfo)?.isLoading;

  let params = useParams();
  const survey = useSelector(selectSurvey);
  const surveyInfo = useSelector(selectSurveyInfo);
  const sendAnswersInfo = useSelector(selectSendAnswersInfo);
  const userAnswersForm = useSelector(selectUserAnswersForm);
  const countryListInfo = useSelector(selectCountryListInfo);
  const TBTInfo = useSelector(selectTBTInfo);
  const config = useSelector(selectConfig);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  //set this state in order to check if the user will come to the acuity test from here or from another devices (see "surveySubmittedHere")
  useEffect(() => {}, [breakpoint.isDesktopOrTabletLandscape]);
  // PRESCRIPTION AND REVIEW STEPS

  const stepInfoByUrl = findStepInfoByUrlParam(params.stepId, survey);
  const stepId = stepInfoByUrl.stepId;
  const stepNumber = stepInfoByUrl.stepNumber;

  const [stepInfo, setStepInfo] = useState<{
    stepNumber: number;
    totalStepNumber: number;
    actualStep: StepType;
  }>({ stepNumber: -1, totalStepNumber: 0, actualStep: null });
  const isLoadingPage =
    !survey ||
    !stepInfo.actualStep ||
    isLabelLoading ||
    countryListInfo.isLoading;

  const formData = useForm<any>({
    mode: "onChange",
    defaultValues: userAnswersForm, //use to stored form by default
  });
  const { handleSubmit, formState } = formData;

  //check if user choose glasses (and, in case, progressive/bifocal-trifocal) or CL prescription type
  const isEyeglassesAnswer = !!formData.getValues("question_6");
  const isCLPrescriptionAnswer = !!formData.getValues("question_7");

  const prescriptionType = useMemo<PrescriptionType>(
    () => ({
      isEyeglasses: isEyeglassesAnswer,
      isCL: isCLPrescriptionAnswer,
      isADDPresent: true,
    }),
    [isEyeglassesAnswer, isCLPrescriptionAnswer]
  );

  const prescriptionAndPDStepInfo = useMemo(
    () => ({
      id: -61,
      idFE: prescriptionStepId,
      title: translate("survey.q9_title"),
      description: translate(
        prescriptionType?.isCL
          ? "survey.q9_description_cl"
          : "survey.q9_description"
      ),
      rule: "and",
      stepLabel: {
        label: translate("survey.q9_step"),
        iconPath:
          "http://localhost:3000/assets/images/icons/survey/q9/prescription.svg",
      },
      recapLabel: translate("survey.q9_recap"),
      recapGroup: {
        iconPath:
          "http://localhost:3000/assets/images/icons/survey/question_group/left-eye.svg",
        label: "",
      },
      questions: [
        {
          id: -63,
          idFE: "prescription_question",
          type: "prescription",
          placeholder: {
            label: translate("survey.q9_a1_title"),
            iconPath:
              "http://localhost:3000/assets/images/icons/survey/q9/prescription.svg",
          },
        },
        {
          id: -62,
          idFE: "pd_question",
          type: "pupillary-distance",
          optional: true,
          placeholder: {
            label: translate("survey.q9_a2_title"),
            iconPath:
              "http://localhost:3000/assets/images/icons/survey/q9/pupillary-distance.svg",
          },
        },
      ],
    }),
    [prescriptionStepId, prescriptionType?.isCL]
  );

  const reviewStepInfo = {
    id: -81,
    idFE: reviewStepId,
    title: translate("survey.q10_title"),
    description: translate("survey.q10_description"),
    rule: "and",
    stepLabel: {
      label: translate("survey.q10_step"),
    },
    recapLabel: translate("survey.q10_recap"),
    questions: [
      {
        id: -82,
        idFE: "recap_question",
        type: "recap",
      },
    ],
  };

  const [sidebarStepsTicks, setSidebarStepsTicks] = useState([]);

  useEffect(() => {
    return () => {
      //store form to re-use it in case the user returns to the survey page
      dispatch(
        surveyActions.setUserAnswersForm({
          userAnswersForm: formData.getValues() || {},
        })
      );
    };
  }, []);

  const [radioSelection, setRadioSelection] = useState<string>(null);
  const radioInsideSelect: RadioInsideSelectProps = useMemo(() => {
    if (!stepInfo?.actualStep?.idFE || stepInfo?.actualStep?.rule !== "or") {
      return undefined;
    }

    return {
      name: "radio_" + stepInfo.actualStep.idFE,
      selected: radioSelection,
      onChange: setRadioSelection,
      hidden: true,
    };
  }, [radioSelection, stepInfo?.actualStep?.idFE]);

  useEffect(() => {
    //scroll to top at page change
    window.scrollTo(0, 0);

    if (stepId === reviewStepId) {
      //clear checkbox entering in the recap step
      formData.setValue("recapCheckbox", false);
    }

    if (stepId === prescriptionStepId) {
      //get old isEyeglassesForm value
      const isEyeglassesForm = formData.getValues("isEyeglassesForm");
      if (isEyeglassesForm !== prescriptionType.isEyeglasses) {
        //if the user selection has changed --> clean old values
        const ids = getPrescriptionIds("prescription_question");
        Object.keys(ids).forEach((key) => {
          formData.unregister(ids[key]);
        });
        formData.unregister([
          "pd_question_iHaveTwoPDs",
          "pd_question_singlePD",
          "pd_question_leftPD",
          "pd_question_rightPD",
          "pd_question_dontknowPD",
        ]);
      }
      //update isEyeglassesForm value
      formData.setValue("isEyeglassesForm", prescriptionType.isEyeglasses);
    }

    //BEGIN SIDEBAR TICKS LOGIC
    const formDataValues = formData?.getValues();

    const calculateStepsTicks = () => {
      let ticks = sidebarStepsTicks;

      survey?.steps?.map((step) => {
        //and rule steps
        if (step.rule === "and") {
          const allQuestionsValid = step.questions.every((question) => {
            return (
              formDataValues?.hasOwnProperty(question.idFE) &&
              !!formDataValues[question.idFE]
            );
          });

          if (allQuestionsValid) {
            ticks[step.idFE] = "green";
          }
        }
        //or rule steps
        else {
          const anyQuestionValid = step.questions.some((question) => {
            return (
              formDataValues?.hasOwnProperty(question.idFE) &&
              !!formDataValues[question.idFE]
            );
          });

          if (anyQuestionValid) {
            ticks[step.idFE] = "green";
          }
        }
      });

      //prescription form check
      const isEyeglassesForm = formDataValues["isEyeglassesForm"];

      if (isEyeglassesForm !== null && isEyeglassesForm !== undefined) {
        //if the user selection has changed --> prescription check needed, red_tick
        if (isEyeglassesForm !== prescriptionType.isEyeglasses) {
          ticks["prescription_step"] = "red";
        } else if (stepId === reviewStepId) {
          ticks["prescription_step"] = "green";
        }
      }

      setSidebarStepsTicks(ticks);
    };
    calculateStepsTicks();
    //END SIDEBAR TICKS LOGIC
  }, [stepId]);

  useEffect(() => {
    if (!surveyInfo.isLoaded && !surveyInfo.isLoading) {
      if (params.stepId) {
        //if step is already set --> start from first step
        // changeStep("", true);
      }

      //if the application doesn't have the survey --> download it
      dispatch(surveyAsyncActions.getSurvey());
    }
  }, [surveyInfo?.isLoaded, surveyInfo?.isLoading]);

  useEffect(() => {
    if (
      !countryListInfo?.isLoading &&
      !countryListInfo?.isLoaded &&
      !(countryListInfo?.countryList?.length > 0)
    ) {
      //get country list
      dispatch(surveyAsyncActions.getCountryList());
    }
  }, [countryListInfo]);

  const changeStep = (stepId: string, replace: boolean = false) => {
    navigate(ROUTING.SURVEY.url.replace(":stepId?", stepId || ""), {
      replace: replace,
      state: replace ? { isReplace: true } : undefined,
    });
  };

  useEffect(() => {
    //if the user has not a survey step --> bring him to the first step
    if (!stepId && survey) {
      if (survey?.steps?.length > 0) {
        changeStep("1", true);
      }
    }
  }, [!!stepId, !!survey]);

  const handleStepChange = (stepIndex: number) => {
    if (stepIndex != undefined) {
      let stepUrl = (stepIndex + 1).toString();
      switch (stepIndex + 1) {
        //prescription_step case
        case stepInfo.totalStepNumber - 1:
          stepUrl = prescriptionStepId;
          break;
        //review_step case
        case stepInfo.totalStepNumber:
          stepUrl = reviewStepId;
          break;
        //all other cases
        default:
          break;
      }
      changeStep(stepUrl);
    }
  };

  useEffect(() => {
    //if TBT and connect have been generated and linked to a payload, show the Success Modal
    if (
      !!TBTInfo?.TBT &&
      !!TBTInfo?.connectToken &&
      stepInfo?.actualStep?.idFE === reviewStepId &&
      TBTInfo?.payload?.status !== "ACUITY_END_DESKTOP"
    ) {
      setShowSuccessModal(true);
    }
  }, [TBTInfo, stepInfo?.actualStep?.idFE]);

  const onSubmit = (data) => {
    console.log("onSubmitStep", { data });

    const callbackSuccess = (
      userQuestionnarieAnswers: QuestionnarieAnswer[]
    ) => {
      //go to the footer.next step if it is not the last step
      if (stepId !== prescriptionStepId && stepId !== reviewStepId) {
        if (stepInfo.stepNumber + 1 < stepInfo.totalStepNumber) {
          //if it is the last survey step -> bring to prescription_step
          if (
            stepInfo.stepNumber + 1 ===
            stepInfo.totalStepNumber - numberOfFurtherSteps
          ) {
            changeStep(prescriptionStepId);
          } else {
            const nextStepIndex = stepInfo.stepNumber + 2;
            changeStep(nextStepIndex.toString());
          }
        }
      } else {
        //it is a prescription/recap step
        switch (stepId) {
          case prescriptionStepId:
            changeStep(reviewStepId);
            break;
          case reviewStepId:
            //we definitely submitted the survey on this device.
            sessionStorage.setItem(
              sessionStorageIds.SURVEY_SUBMITTED_HERE,
              "true"
            );

            userQuestionnarieAnswers.forEach((answer) => {
              answer.labelConnect = [];

              if (answer.q_answer && answer.q_answer.length > 0) {
                answer.labelConnect = answer.q_answer;
              } else {
                answer.answer_id.map((answerId) => {
                  const answerLabel = survey?.steps
                    ?.find((step) => step.id === answer.step_id)
                    ?.questions?.find((question) => question.id === answer.q_id)
                    ?.answers?.find((answer) => answer.id === answerId)?.title;

                  answer.labelConnect.push(answerLabel);
                });
              }
            });

            //add empty comment answers (question 99)
            const existingCommentIndex = userQuestionnarieAnswers.findIndex(
              (question) => question.step_id === 99 && question.q_id === 99
            );
            const comment = {
              step_id: 99,
              q_id: 99,
              q_label: "note",
              q_answer: [""],
              answer_id: [],
              q_prescription: null,
              q_pupillary_distance: null,
              labelConnect: [""],
            };
            if (existingCommentIndex !== -1) {
              userQuestionnarieAnswers[existingCommentIndex] = comment;
            } else {
              userQuestionnarieAnswers.push(comment);
            }
            console.log(
              "userQuestionnarieAnswers after",
              userQuestionnarieAnswers
            );
            dispatch(
              saveSurveyForConnectAndTBTS({
                questionnarie: userQuestionnarieAnswers,
                status: "SURVEY_COMPLETED",
              })
            );
            break;
        }
      }
    };

    const callbackCantProceed = () => {
      navigate(ROUTING.SURVEY_ERROR.url.replace(":stepId", params.stepId));
    };

    const userQuestionnarieAnswers: QuestionnarieAnswer[] = [];

    survey?.steps?.forEach((step, indexStep) => {
      const step_id = step.id; //step id

      step.questions?.forEach((question) => {
        if (
          indexStep > stepInfo.stepNumber &&
          stepInfo?.actualStep?.idFE !== prescriptionStepId &&
          stepInfo?.actualStep?.idFE !== reviewStepId
        ) {
          //do not send answers for step over the actual one
          return;
        }

        const q_id = question.id; //question id
        const q_label =
          question.placeholder?.label || removeHTMLtags(step.title) || ""; //label placeholder translated by user language
        const answer_id = []; //fixed answers (like location, last eye exam, health conditions)
        const q_answer = []; //free answers (like birthday and prescription)
        let q_prescription = null; //prescription answer
        let q_pupillary_distance = null; //pupillary distance answer
        const userAnswer = data[question.idFE];

        if (
          radioSelection &&
          userAnswer &&
          question.idFE !== radioSelection &&
          step.id === stepInfo.actualStep?.id
        ) {
          //if user has selected another question --> clean not selected questions
          formData.setValue(question.idFE, null);
        }

        if (
          //do not send answers for excluded questions in case of questions in mutual exclusion
          userAnswer &&
          (!radioSelection ||
            radioSelection === question.idFE ||
            indexStep !== stepInfo.stepNumber)
        ) {
          switch (question.type) {
            case "localization":
              //localization never has values --> do nothing
              break;
            case "input-birth-date":
              q_answer.push(userAnswer);
              break;
            case "select-state":
              if (Array.isArray(userAnswer)) {
                q_answer.push(userAnswer?.[0]?.id);
              } else {
                q_answer.push(userAnswer?.id);
              }
              break;
            case "select":
              if (Array.isArray(userAnswer)) {
                answer_id.push(+userAnswer?.[0]?.id);
              } else {
                answer_id.push(+userAnswer?.id);
              }
              break;
            case "select-list":
              answer_id.push(+userAnswer.replace("answer_", ""));
              break;
            case "multiselect":
              userAnswer.forEach((_) => {
                answer_id.push(+_.id);
              });
              break;
          }
        }

        //add user answer
        userQuestionnarieAnswers.push({
          step_id,
          q_id,
          q_label,
          answer_id,
          q_answer,
          q_prescription,
          q_pupillary_distance,
          labelConnect: [],
        });
      });
    });

    if (
      stepInfo?.actualStep?.idFE === prescriptionStepId ||
      stepInfo?.actualStep?.idFE === reviewStepId
    ) {
      const ids = getPrescriptionIds("prescription_question");
      let prescriptionData = {};
      Object.keys(ids).forEach((key) => {
        prescriptionData[key] = data[ids[key]];
      });
      const sanitizedData: PrescriptionData = sanitizePrescriptionValues(
        prescriptionData as PrescriptionData
      );
      //prescription and pupillary distance page
      //prescription answers
      const q_prescription = {
        sameValues: !!sanitizedData.sameBrandBothEyes,
        CLBrandAndModel: sanitizedData.lensBrandRight || "",
        CLBrandAndModelLeft: sanitizedData.lensBrandLeft || "",
        rightBaseCurve: sanitizedData.rightBaseCurve || "",
        leftBaseCurve: sanitizedData.leftBaseCurve || "",
        rightDiameter: sanitizedData.rightDiameter || "",
        leftDiameter: sanitizedData.leftDiameter || "",
        rightSphere: sanitizedData.rightSphere || "",
        leftSphere: sanitizedData.leftSphere || "",
        rightCylinder: sanitizedData.rightCylinder || "",
        leftCylinder: sanitizedData.leftCylinder || "",
        rightAxis: sanitizedData.rightAxis || "",
        leftAxis: sanitizedData.leftAxis || "",
        rightAdd: sanitizedData.rightAdd || "",
        leftAdd: sanitizedData.leftAdd || "",
        rightHPrism: sanitizedData.rightHPrism || "",
        leftHPrism: sanitizedData.leftHPrism || "",
        rightHPrismBaseDir: sanitizedData.rightHPrismBaseDir || "",
        leftHPrismBaseDir: sanitizedData.leftHPrismBaseDir || "",
        rightVPrism: sanitizedData.rightVPrism || "",
        leftVPrism: sanitizedData.leftVPrism || "",
        rightVPrismBaseDir: sanitizedData.rightVPrismBaseDir || "",
        leftVPrismBaseDir: sanitizedData.leftVPrismBaseDir || "",
      };

      //pupillary distance answers
      const pdData = getPupillaryDistanceFinalData({
        iHaveTwoPDs: data.pd_question_iHaveTwoPDs || false,
        leftPD: data.pd_question_leftPD || "",
        rightPD: data.pd_question_rightPD || "",
        iDontKnowMyPD: data.pd_question_dontknowPD || false,
      });

      const q_pupillary_distance = {
        leftEye: pdData.leftPD || "",
        rightEye: pdData.rightPD || "",
        dontKnow: pdData.iDontKnowMyPD,
      };

      //add prescription answer
      userQuestionnarieAnswers.push({
        step_id: -61,
        q_id: -62,
        q_label: translate("survey.q9_a1_title"),
        answer_id: [],
        q_answer: [],
        q_prescription,
        q_pupillary_distance: null,
        labelConnect: [],
      });
      //add pupillary distance answer
      userQuestionnarieAnswers.push({
        step_id: -61,
        q_id: -63,
        q_label: translate("survey.q9_a2_title"),
        answer_id: [],
        q_answer: [],
        q_prescription: null,
        q_pupillary_distance,
        labelConnect: [],
      });
    }

    dispatch(
      sendAnswers({
        stepId: stepInfo.actualStep?.id,
        callbackSuccess: () => callbackSuccess(userQuestionnarieAnswers),
        callbackCantProceed: callbackCantProceed,
        isLastStep: stepInfo?.actualStep?.idFE === reviewStepId,
        questionnarie: userQuestionnarieAnswers,
      })
    );
  };

  useEffect(() => {
    let stepNumber = -1;
    let totalStepNumber = 0;
    let actualStep = null;
    if (stepId && survey?.steps?.length > 0) {
      stepNumber = survey.steps.findIndex((step) => step.idFE === stepId);
      if (stepNumber >= 0) {
        actualStep = survey.steps[stepNumber];

        //actual step is changed --> reset radio selection
        if (radioSelection) {
          setRadioSelection(null);
        }

        //actual step is changed --> reset isOneValueSelected
        if (isOneValueSelected) {
          setIsOneValueSelected(false);
        }

        //actual step is changed --> trigger form (the form sometimes is lazy)
        setTimeout(() => {
          formData.trigger();
        }, 300);
      } else {
        // if actual step is prescription_step or review_step
        if (stepId === prescriptionStepId || stepId === reviewStepId) {
          stepNumber =
            stepId === prescriptionStepId
              ? totalStepNumber - 1
              : totalStepNumber;
          actualStep =
            stepId === prescriptionStepId
              ? prescriptionAndPDStepInfo
              : reviewStepInfo;
        } else {
          //otherwise, actual step doesn't exists --> redirect to first step
          if (survey?.steps?.length > 0) {
            changeStep("1", true);
          }
        }
      }
      totalStepNumber = survey.steps.length + numberOfFurtherSteps;
    }

    setStepInfo({
      stepNumber,
      totalStepNumber,
      actualStep,
    });
  }, [stepId, !!survey]);

  const [isOneValueSelected, setIsOneValueSelected] = useState(false);

  //LAYOUT EFFECT -> EVERY STEP CHANGE, SET THE FOCUS ON THE TITLE OF SURVEYSTEPCONTENT
  useLayoutEffect(() => {
    document.querySelector("." + stylePageCardMobile.title)?.focus();
  }, [stepInfo?.actualStep?.id]);

  useEffect(() => {
    if (stepInfo?.actualStep?.rule === "or") {
      let questions = stepInfo?.actualStep?.questions?.map((_) => _.idFE);
      const userAnswers = formData?.getValues() || {};

      let oneValueSelectedTemp =
        Object.keys(userAnswers)
          ?.filter((key) => !!userAnswers[key])
          .filter((key) => questions.includes(key))?.length > 0;

      if (oneValueSelectedTemp !== isOneValueSelected) {
        //check to prevent some useless re-rendering
        setIsOneValueSelected(oneValueSelectedTemp);

        if (oneValueSelectedTemp) {
          //to trigger validation, otherwise it is not early applied the change of required clause
          setTimeout(() => {
            formData.trigger();
          }, 100);
        }
      }
    }
  }, [JSON.stringify(formData?.getValues() || {}), stepInfo?.actualStep?.rule]);

  //in order to detect which will be the question type (and relative component to render).
  // This is needed in order to choose if form will be flex-column or flex-row oriented.
  const determineCurrentComponentType = (questions) => {
    const firstNonSelectListType = questions.find(
      (question) => question.type !== "select-list"
    )?.type;
    return firstNonSelectListType || "select-list";
  };
  const currentComponentType = stepInfo.actualStep?.questions
    ? determineCurrentComponentType(stepInfo.actualStep.questions)
    : "select-list";

  const recapCheckbox = (
    <>
      {stepInfo?.actualStep?.idFE === reviewStepId && (
        <Controller
          control={formData.control}
          name={"recapCheckbox"}
          rules={{
            required: true,
          }}
          render={({ field }) => {
            useEffect(() => {
              //trigger to handle the new field in the form
              formData.trigger();
            }, []);

            return (
              <Checkbox
                checked={field.value}
                onChange={(value) => field.onChange(value)}
                id={"recapCheckbox"}
                name={"recapCheckbox"}
                className={style.recapCheckbox}
              >
                {translate("survey.recap_checkbox")}
              </Checkbox>
            );
          }}
        />
      )}
    </>
  );

  //do not display geolocation error if user select a state
  const [locationAnswerType, setLocationAnswerType] =
    useState<string>("geolocation");

  const [closeAllMenus, setCloseAllMenus] = useState<boolean>(false);
  const closeAllMenusFunc = () => {
    setCloseAllMenus(true);
    setTimeout(() => setCloseAllMenus(false), 100);
  };

  return (
    <PageStandard
      className={`${style.Background} ${stylePageCardMobile.pageCardMobile}`}
      classNamePageBody={stylePageCardMobile.pageCardMobileBody}
      footerInfo={{
        backgroundWhite: true,
        showLeftButton: true,
        iconLeftButton: "leftArrow",
        classNameLeftButton: stylePageCardMobile.footerLeftButton,
        analyticsInfoLeftButton: {
          "data-element-id": "RxRenewal_Eligibility-Back",
          "data-analytics_available_call": 0,
        },
        showRightButton: true,
        classNameRightButton: stylePageCardMobile.footerRightButton,
        labelRightButton: translate("footer.next"),
        rightButtonType: "submit",
        rightButtonForm: "stepForm",
        disabledRightButton:
          !formState.isValid || sendAnswersInfo.isLoading || isLoadingPage,
        analyticsInfoRightButton: {
          "data-element-id": "RxRenewal_Eligibility-Next",
          "data-description": formatAnalyticsInnerText(
            translate("footer.next")
          ),
          "data-analytics_available_call": 0,
        },
        elementNextToRightButton:
          breakpoint.isDesktopOrTabletLandscape && recapCheckbox,
        otherElementsTop: breakpoint.isPhoneOrTabletPortrait && recapCheckbox,
      }}
      showHeader={true}
      showBackgroundVideo={true}
    >
      <div
        className={`${stylePageCardMobile.overPageContainer} ${style.overPageContainer}`}
      >
        <p className={stylePageCardMobile.overPageTitle}>
          {translate("survey.eligibility_check")}
        </p>

        <p
          className={stylePageCardMobile.overPageSteps}
          dangerouslySetInnerHTML={printHTML(
            stepInfo.totalStepNumber > 0
              ? translate("current_prescription.steps_recap")
                  ?.replace("{{step}}", stepNumber?.toString())
                  .replace(
                    "{{totalSteps}}",
                    stepInfo.totalStepNumber.toString()
                  )
              : "​"
          )}
        />
      </div>

      <div
        className={`${stylePageCardMobile.pageBodyCard} ${stylePageCardMobile.noPaddingBodyCard}`}
      >
        {breakpoint.isDesktopOrTabletLandscape && (
          <Sidebar
            currentStepIndex={stepNumber - 1}
            totalSteps={stepInfo.totalStepNumber}
            stepsTicks={sidebarStepsTicks}
            changeStepFunction={handleStepChange}
          />
        )}
        <div className={`${style.PageBody}`}>
          {isLoadingPage ? (
            <Spinner className={style.spinner} />
          ) : (
            <div
              className={`${style.SurveyStepContent} ${
                stepInfo?.actualStep?.idFE === reviewStepId
                  ? style.recapSurveyStepContent
                  : ""
              }`}
            >
              {stepInfo.actualStep && (
                <>
                  <p
                    className={stylePageCardMobile.title}
                    dangerouslySetInnerHTML={printHTML(
                      stepInfo.actualStep.title
                    )}
                    aria-label={removeHTMLtags(stepInfo.actualStep.title)}
                    tabIndex={0}
                  />
                  <p
                    className={`${stylePageCardMobile.description} ${style.description}`}
                    dangerouslySetInnerHTML={printHTML(
                      stepInfo.actualStep.description
                    )}
                  />

                  <div className={style.StepFormContainer}>
                    <form
                      id="stepForm"
                      className={`${style.StepForm} ${
                        currentComponentType === "select-list"
                          ? style.ColumnDirectionForm
                          : style.RowDirectionForm
                      }`}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {stepInfo.actualStep.questions
                        ?.sort((a, b) => a.id - b.id)
                        .map((question, index) => (
                          <React.Fragment key={question.idFE}>
                            {!!stepInfo.actualStep.ruleLabel && index > 0 && (
                              <OrSeparator
                                label={stepInfo.actualStep.ruleLabel}
                              />
                            )}
                            {
                              {
                                localization: (
                                  <>
                                    <GeolocationButton
                                      question={question}
                                      isThisButtonSelected={
                                        locationAnswerType === "geolocation"
                                      }
                                      onClick={() =>
                                        setLocationAnswerType("geolocation")
                                      }
                                      stepQuestions={
                                        stepInfo.actualStep.questions
                                      }
                                      formData={formData}
                                    />
                                  </>
                                ),
                                "select-state": (
                                  <SelectState
                                    question={question}
                                    onClick={() =>
                                      setLocationAnswerType("select-state")
                                    }
                                    formData={formData}
                                    optional={
                                      question.optional || isOneValueSelected
                                    }
                                  />
                                ),
                                "input-birth-date": (
                                  <SurveyBirthdayInput
                                    question={question}
                                    formData={formData}
                                    optional={
                                      question.optional || isOneValueSelected
                                    }
                                  />
                                ),
                                select: (
                                  <SelectSurvey
                                    isMulti={false}
                                    question={question}
                                    forcedMenuClosingInfo={{
                                      forcedMenuClosing: closeAllMenus,
                                      isOrSelect:
                                        stepInfo.actualStep.ruleLabel === "or",
                                      fallbackCloseAllMenus: closeAllMenusFunc,
                                    }}
                                    formData={formData}
                                    optional={
                                      question.optional || isOneValueSelected
                                    }
                                    radioInsideSelect={radioInsideSelect}
                                  />
                                ),
                                "select-list": (
                                  <SelectList
                                    question={question}
                                    formData={formData}
                                    optional={
                                      question.optional || isOneValueSelected
                                    }
                                  />
                                ),
                                multiselect: (
                                  <SelectSurvey
                                    isMulti={true}
                                    question={question}
                                    formData={formData}
                                    optional={
                                      question.optional || isOneValueSelected
                                    }
                                    radioInsideSelect={radioInsideSelect}
                                  />
                                ),
                                prescription: (
                                  <PrescriptionForm
                                    question={question}
                                    formData={formData}
                                    prescriptionType={prescriptionType}
                                  ></PrescriptionForm>
                                ),
                                "pupillary-distance": (
                                  <SelectPD
                                    question={question}
                                    formData={formData}
                                    optional={true}
                                    prescriptionType={prescriptionType}
                                  ></SelectPD>
                                ),
                                recap: <RecapData formData={formData} />,
                              }[question.type]
                            }
                          </React.Fragment>
                        ))}
                    </form>
                    {stepInfo.actualStep.idFE === "prescription_step" && (
                      <div className={style.HowToReadPrescriptionContainer}>
                        <HowToReadPrescriptionButton
                          isCL={prescriptionType?.isCL}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {showSuccessModal && (
        <>
          <ModalQrCodeSurvey
            isOpen={showSuccessModal}
            isDesktopOrTabletLandscape={breakpoint.isDesktopOrTabletLandscape}
            closeAction={() => setShowSuccessModal(false)}
            icon="check"
            message={
              breakpoint.isPhoneOrTabletPortrait
                ? translate("survey.success_modal_title_mobile")
                : translate("survey.success_modal_title_desktop")
            }
            description={
              breakpoint.isPhoneOrTabletPortrait
                ? translate("survey.success_modal_description_mobile")
                : translate("survey.success_modal_description_desktop")
            }
            {...(breakpoint.isPhoneOrTabletPortrait && {
              subdescription: translate(
                "survey.success_modal_subdescription_mobile"
              ),
              ctaLabel: translate("survey.success_modal_cta_mobile"),
              ctaAction: () => navigate(ROUTING.LEGAL_DISCLAIMER.url),
            })}
            {...(breakpoint.isDesktopOrTabletLandscape && {
              messageLoading: translate("survey.loading_modal_title_desktop"),
              descriptionLoading: translate(
                "survey.loading_modal_description_desktop"
              ),
            })}
            qrCode={{
              //QR code's url. If already in mobile, we don't need QRCode. Otherwise, TBT will be generated and QRCode is needed
              src: breakpoint.isPhoneOrTabletPortrait
                ? ""
                : window.location.origin +
                  ROUTING.LEGAL_DISCLAIMER.url +
                  "?TBT=" +
                  TBTInfo?.TBT +
                  "&connectToken=" +
                  TBTInfo.connectToken +
                  "&patientId=" +
                  config?.config?.patientId +
                  "&userId=" +
                  config?.config?.userId +
                  "&cid=RX-RNW_OK" + //cid param is requested by the analytics team
                  (sessionStorage.getItem("keyLabelMode") === "true"
                    ? "&keyLabelMode=true"
                    : ""), //keyLabelMode for labels check logic
              description: translate("qrcode.scan_descr_2"),
              description_qr_reopened: translate("qrcode.scan_descr_2_variant"),
            }}
          />
        </>
      )}
    </PageStandard>
  );
};

export default SurveyPage;
