import ModalCheck from "@components/common/ModalCheck/component";
import { useTranslation } from "@hooks/useTranslation";
import { useUtag } from "@hooks/useUtag";
import { selectConfig } from "@slices/configSlice";
import { saveSurveyForConnectAndTBTS } from "@slices/surveySlice";
import { utilityActions, utilityAsyncActions } from "@slices/utilitySlice";
import { ROUTING } from "@utility/routingUtility";
import {
  cleanSessionStorage,
  getPayloadInSession,
  sessionStorageIds,
} from "@utility/sessionStorageUtility";
import { exitRxr } from "@utility/utility";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function TimeoutModal() {
  const translate = useTranslation();
  const navigate = useNavigate();
  const config = useSelector(selectConfig);
  const { sendAnalytics } = useUtag({ sendAtEveryPage: false });
  const dispatch = useDispatch();

  const restartCTAAction = () => {
    //clean tokens because they are expired soo we can restart from 0
    cleanSessionStorage();
    dispatch(utilityActions.setShowTimeoutPopup({ showTimeoutPopup: false }));
    navigate(ROUTING.START_1.url);
  };

  const continueCTAACtion = async () => {
    //get saved payload and resave data for connect and TBTS (-> new tokens generated)
    const savedTBTPayload = getPayloadInSession();

    if (savedTBTPayload) {
      await dispatch(
        saveSurveyForConnectAndTBTS({
          questionnarie: savedTBTPayload.eligibility_questionnarie,
          status: savedTBTPayload.status,
          acuityTest: savedTBTPayload.tests,
        })
      );
    }
    dispatch(utilityActions.setShowTimeoutPopup({ showTimeoutPopup: false }));
    history.go(0);
  };

  const leaveCTAACtion = () => {
    // clean tokens because useless, we're leaving
    dispatch(utilityActions.setShowTimeoutPopup({ showTimeoutPopup: false }));
    cleanSessionStorage();

    dispatch(
      utilityAsyncActions.invokeEcommerceFunction({
        functionId: "updateAssessmentStatus",
        finallyCallback: () => {
          exitRxr(config.config?.headerBackLinkUrl);
        },
      })
    );
  };

  useEffect(() => {
    const TBT = sessionStorage.getItem(sessionStorageIds.TBT);

    sendAnalytics("ERROR_EVENT", [""], {
      Events_Error: "1",
      Error_Source: "Server",
      Error_Code: "Timeout error",
      Error_Details: "Token " + TBT + " is expired or doesn't exist",
      Error_Message: translate("timeout.title"),
    });
  }, []);

  return (
    <ModalCheck
      isOpen={true}
      icon="timeout"
      message={translate("timeout.title")}
      description={translate("timeout.description")}
      ctaPrimaryLabel={translate("timeout.continue_bottom_label")}
      ctaPrimaryAction={continueCTAACtion}
      ctaLabel={translate("timeout.restart_bottom_label")}
      ctaAction={restartCTAAction}
      otherCTAs={[
        {
          label: translate("timeout.leave_bottom_label"),
          action: leaveCTAACtion,
          disabled: false,
        },
      ]}
    />
  );
}

export default TimeoutModal;
