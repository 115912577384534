import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import PageStandard from "@components/common/PageStandard/component";
import { useTranslation } from "@hooks/useTranslation";
import style from "./style.module.scss";
import VideoTutorial from "@components/common/VideoTutorial/component";
import { useDispatch, useSelector } from "react-redux";
import { acuityActions, selectEnableNextButtonMap } from "@slices/acuitySlice";
import ModalCountdown from "@components/common/ModalCountdown/component";
import { useGetSetupVideos } from "@hooks/useGetSetupVideos";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";
import GyroscopeManagementComponent from "@components/common/GyroscopeManagementComponent/component";
import { isAnticheatingEnabled } from "@utility/utility";

type VideoInfoMapType = {
  [key: string]: VideoInfoType;
};

type VideoInfoType = {
  id: string;
  footerAction?: () => void;
  video?: string;
  audio?: string;
};

type AcuityVideoPageProps = {};

function AcuityVideoPage(props: AcuityVideoPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslation();
  const enableNextButtonMap = useSelector(selectEnableNextButtonMap);

  const setupVideosSx = useGetSetupVideos("acuity-sx");
  const setupVideosDx = useGetSetupVideos("acuity-dx");

  let videoId = useParams()?.videoId;

  const [showCountdown, setShowCountdown] = useState(false);
  const [stopVideo, setStopVideo] = useState(false);
  const [showGyroscopeManagementModal, setShowGyroscopeManagementModal] =
    useState(false);

  const videoInfoMap: VideoInfoMapType = {
    "acuity-right-eye": {
      id: "acuity-right-eye",
      footerAction: () => {
        if (isAnticheatingEnabled()) {
          setShowGyroscopeManagementModal(true);
        } else {
          navigate(
            ROUTING.ACUITY_VIDEO.url.replace(":videoId?", "acuity-flip-phone")
          );
        }
      },
      video: setupVideosDx?.[0]?.uri || "",
      audio: "",
    },
    "acuity-flip-phone": {
      id: "acuity-flip-phone",
      footerAction: () => {
        setStopVideo(true);
        setShowCountdown(true);
        setTimeout(() => {
          navigate(
            ROUTING.ACUITY_TEST.url.replace(":eyeParam", "right-eye-tutorial")
          );
        }, 3000);
      },
      video: setupVideosDx?.[1]?.uri || "",
      audio: "",
    },
    "acuity-left-eye": {
      id: "acuity-left-eye",
      footerAction: () => {
        setStopVideo(true);
        setShowCountdown(true);
        setTimeout(() => {
          navigate(
            ROUTING.ACUITY_TEST.url.replace(":eyeParam", "left-eye-tutorial")
          );
        }, 3000);
      },
      video: setupVideosSx?.[0]?.uri || "",
      audio: "",
    },
    "acuity-left-eye-wrong": {
      id: "acuity-left-eye-wrong",
      footerAction: () => {
        setStopVideo(true);
        setShowCountdown(true);
        setTimeout(() => {
          navigate(
            ROUTING.ACUITY_TEST.url.replace(":eyeParam", "left-eye-tutorial")
          );
        }, 3000);
      },
      video: setupVideosDx?.[6]?.uri || "",
      audio: "",
    },
    "acuity-flip-back-phone-right": {
      id: "acuity-flip-back-phone-right",
      footerAction: () => {
        navigate(ROUTING.ACUITY_WRONG.url.replace(":eyeParam", "right"));
      },
      video: setupVideosDx?.[5]?.uri || "",
      audio: "",
    },
    "acuity-flip-back-phone-left": {
      id: "acuity-flip-back-phone-left",
      footerAction: () => {
        navigate(ROUTING.ACUITY_WRONG.url.replace(":eyeParam", "left"));
      },
      video: setupVideosDx?.[5]?.uri || "",
      audio: "",
    },
    "acuity-flip-back-phone-end": {
      id: "acuity-flip-back-phone-end",
      footerAction: () => {
        navigate(ROUTING.ACUITY_END.url);
      },
      video: setupVideosSx?.[2]?.uri || "",
      audio: "",
    },
  };

  const currentVideoInfo: VideoInfoType = videoInfoMap[videoId];

  useEffect(() => {
    if (!videoId || !currentVideoInfo) {
      navigate(ROUTING.ACUITY_START.url);
    }
  }, [!!videoId, !!currentVideoInfo]);

  return (
    <PageStandard
      className={`${style.acuityTutorial}`}
      classNamePage={style.acuityTutorialPage}
      footerInfo={{
        className: style.footer,
        showLeftButton: true,
        classNameLeftButton: style.footerLeftButton,
        iconLeftButton: "leftArrow",
        labelLeftButton: "",
        analyticsInfoLeftButton: {
          "data-element-id": "RxRenewal_Test-Back",
          "data-analytics_available_call": 0,
        },
        showRightButton: true,
        classNameRightButton: style.footerRightButton,
        labelRightButton: translate("footer.next"),
        actionRightButton: currentVideoInfo?.footerAction,
        disabledRightButton: !enableNextButtonMap[currentVideoInfo?.id],
        analyticsInfoRightButton: {
          "data-element-id": "RxRenewal_Test-Next",
          "data-description": formatAnalyticsInnerText(
            translate("footer.next")
          ),
          "data-analytics_available_call": 0,
        },
      }}
      showHeader={false}
    >
      <VideoTutorial
        id={currentVideoInfo?.id}
        key={currentVideoInfo?.id}
        classNameVideoContainer={style.videoContainer}
        src={currentVideoInfo?.video}
        ariaLabel={""}
        width={"100%"}
        height={"auto"}
        autoPlay={true}
        controls={false}
        playsInline={true}
        // audio={currentStep.audio}
        onEnded={() => {
          if (!enableNextButtonMap[currentVideoInfo?.id]) {
            dispatch(
              acuityActions.enableNextButton({
                idToEnable: currentVideoInfo?.id,
              })
            );
          }
        }}
        stopVideo={stopVideo}
      />
      {showGyroscopeManagementModal && (
        <GyroscopeManagementComponent
          isOpen={showGyroscopeManagementModal}
          nextAction={() => {
            setShowGyroscopeManagementModal(false);
            navigate(
              ROUTING.ACUITY_VIDEO.url.replace(":videoId?", "acuity-flip-phone")
            );
          }}
        />
      )}
      {showCountdown && <ModalCountdown />}
    </PageStandard>
  );
}

export default AcuityVideoPage;
