/*
 * THIS COMPONENT IS DERIVED BY:
 * @Author: Richard.CAI
 * @Date: 2023-06-13 9:30:00
 * @LastEditTime: 2023-06-16 10:00:00
 * @LastEditors: Richard.CAI
 * @Description:
 * @FilePath: /RxRenewal/src/views/Tips/index.tsx
 * Copyright © 2023 Essilor. All rights reserved.
 */
import Button from "@components/common/Button/component";
import LazyImage from "@components/common/LazyImage/LazyImage";
import LazyVideo from "@components/common/LazyVideo/LazyVideo";
import ModalDetectDevice from "@components/common/ModalDetectDevice/component";
import Tooltip from "@components/common/Tooltip/component";
import { getPreviousPageLabel } from "@hooks/getPreviousPageLabel";
import { useLandoltC } from "@hooks/useLandoltC";
import { useTranslation } from "@hooks/useTranslation";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";
import { printHTML } from "@utility/htmlUtility";
import { ROUTING } from "@utility/routingUtility";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "./style.module.scss";
import { utilityAsyncActions } from "@slices/utilitySlice";
import { useDispatch } from "react-redux";
import { setPPIInSession } from "@utility/sessionStorageUtility";

type SetupCalibrationRulerPageProps = {};

function SetupCalibrationRulerPage(props: SetupCalibrationRulerPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslation();
  const { storeCInfo } = useLandoltC(10, false);
  const previousPageLabel = getPreviousPageLabel();

  let tutorialStep = useParams()?.tutorialStep;

  let X: number,
    startX: number,
    xMax: number = (window.innerWidth * 229) / 375,
    xMin: number = (window.innerWidth * 48) / 375;
  // let bgTimer, upTipsTimer, cardTimer, belowTipsTimer, clickTriggerTimer;
  const instance = useRef({
    bgTimer: null,
    upTipsTimer: null,
    cardTimer: null,
    belowTipsTimer: null,
    clickTriggerTimer: null,
    newHeight: 0,
  });
  const bgH = window.innerHeight - (window.innerWidth * 265) / 375;
  const topV = window.innerHeight - (window.innerWidth * 245) / 375;
  const dragObj = useRef<HTMLButtonElement>(null);
  const [pWidth, setPWidth] = useState(0); // the width of progress
  const [circleTips, setCircleTips] = useState(false); // whether show the circle tips
  const [cardTips, setCardTips] = useState(false); // whether show the card tips
  const [bgHeight, setBgHeight] = useState(0); // the height of test-bg
  //const [showBg, setShowBgt] = useState(false) // whether show the white background
  const [topval, setTopval] = useState(0); // the distance of from the div of 'border-btm' to the page top
  const [ppi, setPPI] = useState(0.0); // ppi
  //const [showBg, setShowBg] = useState(false) // whether show the div of testbg
  const [aniBg, setAniBg] = useState(false); // whether show the div of testbg
  const [creditCard, setCreditCard] = useState(false); // whether show the div of credit-card
  const [minusShadow, setMinusShadow] = useState(false); // whether show the shadow of the minus button
  const [addShadow, setAddShadow] = useState(true); // whether show the shadow of the add button
  const [clickTrigger, setClickTrigger] = useState(false); // whether the circle button can be used
  const [circleDistance, setCircleDistance] = useState(xMin); // the distance from the circle button to the minus button
  const [xAxis, setXAxis] = useState(0); // the value of X
  const [aniNextFirstTime, setAniNextFirstTime] = useState(true); //to run aniNext function just once

  const scaleWidth = xMax - xMin + 48;
  const operateCompWidth = scaleWidth + 48 * 2;
  const operateCompTranslateX =
    0 - (window.innerWidth - 50 - operateCompWidth) / 2;
  const operateCompTranslateXFinal =
    operateCompTranslateX < 0 ? operateCompTranslateX : 0;

  useEffect(() => {
    // setAnimationLeft(true)
    // setAnimationTop(true)
    showBgAnimation();
    return () => {
      console.log(
        "bgTimer",
        instance.current.bgTimer,
        instance.current.cardTimer,
        instance.current.belowTipsTimer,
        instance.current.upTipsTimer,
        instance.current.clickTriggerTimer
      );
      clearTimeout(instance.current.bgTimer);
      clearTimeout(instance.current.cardTimer);
      clearTimeout(instance.current.belowTipsTimer);
      clearTimeout(instance.current.upTipsTimer);
      clearTimeout(instance.current.clickTriggerTimer);
      instance.current.bgTimer = null;
      instance.current.cardTimer = null;
      instance.current.belowTipsTimer = null;
      instance.current.upTipsTimer = null;
      instance.current.clickTriggerTimer = null;
    };
  }, []);

  const showBgAnimation = () => {
    instance.current.bgTimer = setTimeout(() => {
      //setShowBg(true);
      setAniBg(true);
    }, 2600);
  };

  const aniNext = () => {
    console.log("aniNext", { aniNextFirstTime });
    if (aniNextFirstTime) {
      setAniNextFirstTime(false);

      instance.current.upTipsTimer = setTimeout(() => {
        setCardTips(true);
      }, 800);
      instance.current.cardTimer = setTimeout(() => {
        //setCardTips(false)
        setCreditCard(true);
      }, 5400);
      instance.current.belowTipsTimer = setTimeout(() => {
        setCircleTips(true);
      }, 9000);
      instance.current.clickTriggerTimer = setTimeout(() => {
        setClickTrigger(true);
      }, 16600);
    }
  };

  // minus the distance
  const minusHandle = (l: any) => {
    if (!clickTrigger || xAxis <= xMin) return;
    creditCard && setCreditCard(false);
    let newTopVal; // the distance from the div of 'border-btm' to the page's top
    let deviceRatio = window.devicePixelRatio;
    let cardWidth = 54; // width of credit card
    let curPpi, tempPpi;
    console.log(l);
    if (l && l > 0) {
      newTopVal = l + (window.innerWidth * 20) / 375 + 1;
      setBgHeight(l + 1);
      curPpi = deviceRatio
        ? (deviceRatio * (l + 1) * 25.4) / cardWidth
        : ((l + 1) * 25.4) / cardWidth;
      tempPpi = parseFloat(curPpi.toFixed(2));
      setPPI(tempPpi);
      let curBGHeight = window.innerHeight - (window.innerWidth * 265) / 375;
      let stretchW = (window.innerWidth * 181) / 375;
      let newHeight = l + 1;
      let curPWidth = ((curBGHeight - newHeight) * stretchW) / curBGHeight;
      let finalWidth = parseFloat(curPWidth.toFixed(2));
      setPWidth(finalWidth);
      setCircleDistance(finalWidth + xMin);
    } else {
      newTopVal = window.innerHeight - (window.innerWidth * 245) / 375 + 1;
      let newL = window.innerHeight - (window.innerWidth * 265) / 375 + 1;
      setBgHeight(newL);
      curPpi = deviceRatio
        ? (deviceRatio * newL * 25.4) / cardWidth
        : (newL * 25.4) / cardWidth;
      tempPpi = parseFloat(curPpi.toFixed(2));
      setPPI(tempPpi);
    }
    // localStorage.setItem('ppi', String(tempPpi))
    console.log("ppi", tempPpi);
    //setBtm(newBtm)
    setTopval(newTopVal);
  };

  // add the distance
  const addHandle = (l: any) => {
    console.log("x", xAxis);
    console.log("xMax", xMax);
    if (!clickTrigger || xAxis >= xMax) return;
    creditCard && setCreditCard(false);
    let newTopVal; // the distance from the div of 'border-btm' to the page's top
    let deviceRatio = window.devicePixelRatio;
    let cardWidth = 54; // width of credit card
    let curPpi, tempPpi;
    if (l && l > 0) {
      newTopVal = l + (window.innerWidth * 20) / 375 - 1;
      setBgHeight(l - 1);
      curPpi = deviceRatio
        ? (deviceRatio * (l - 1) * 25.4) / cardWidth
        : ((l - 1) * 25.4) / cardWidth;
      tempPpi = parseFloat(curPpi.toFixed(2));
      setPPI(tempPpi);
      let curBGHeight = window.innerHeight - (window.innerWidth * 265) / 375;
      let stretchW = (window.innerWidth * 181) / 375;
      let newHeight = l - 1;
      let curPWidth = ((curBGHeight - newHeight) * stretchW) / curBGHeight;
      let finalWidth = parseFloat(curPWidth.toFixed(2));
      setPWidth(finalWidth);
      setCircleDistance(finalWidth + xMin);
    } else {
      newTopVal = window.innerHeight - (window.innerWidth * 245) / 375 - 1;
      let newL = window.innerHeight - (window.innerWidth * 265) / 375 - 1;
      setBgHeight(newL);
      curPpi = deviceRatio
        ? (deviceRatio * newL * 25.4) / cardWidth
        : (newL * 25.4) / cardWidth;
      tempPpi = parseFloat(curPpi.toFixed(2));
      setPPI(tempPpi);
    }
    // localStorage.setItem('ppi', String(tempPpi))
    console.log("ppi", tempPpi);
    //setBtm(newBtm)
    setTopval(newTopVal);
  };

  // trigger the function when user drags the bar handle
  const FnStart = (ev: any): void => {
    if (!clickTrigger) return;
    setCircleTips(false);
    if (dragObj.current) {
      startX = ev.changedTouches[0].pageX - dragObj.current?.offsetLeft;
      document.ontouchmove = FnMove;
      document.ontouchend = FnEnd;
    }
  };
  // start to move
  const FnMove = (ev: TouchEvent): void => {
    creditCard && setCreditCard(false);
    X = ev.changedTouches[0].pageX - startX;
    setXAxis(X);
    console.log(X);
    if (X <= xMin) {
      setMinusShadow(false);
    } else {
      setMinusShadow(true);
    }
    if (X >= xMax) {
      setAddShadow(false);
    } else {
      setAddShadow(true);
    }
    if (X < xMin || X >= xMax) return;
    if (dragObj.current) {
      let curBGHeight = window.innerHeight - (window.innerWidth * 265) / 375;
      let stretchW = (window.innerWidth * 181) / 375;
      dragObj.current.style.left = X + "px";
      let curPWidth = X - (window.innerWidth * 48) / 375;
      instance.current.newHeight =
        curBGHeight - (curPWidth * curBGHeight) / stretchW;
      //let newBtm = window.innerHeight - newHeight - window.innerWidth*10/375;
      let newTopVal =
        instance.current.newHeight + (window.innerWidth * 20) / 375;
      setPWidth(curPWidth);
      setBgHeight(instance.current.newHeight);
      //setBtm(newBtm)
      setTopval(newTopVal);
    }
  };
  // end moving
  const FnEnd = () => {
    document.ontouchmove = null;
    document.ontouchend = null;
    let deviceRatio = window.devicePixelRatio;
    let cardWidth = 54; // width of credit card
    let curPpi, tempPpi;
    curPpi = deviceRatio
      ? (deviceRatio * instance.current.newHeight * 25.4) / cardWidth
      : (instance.current.newHeight * 25.4) / cardWidth;
    tempPpi = parseFloat(curPpi.toFixed(2));
    console.log(
      "newHeight, tempPpi: ",
      instance.current.newHeight,
      " / ",
      tempPpi
    );
    setPPI(tempPpi);
    // localStorage.setItem('ppi', String(tempPpi))
    console.log("ppi", tempPpi);
  };

  // navigate to the next page
  const onSubmit = () => {
    if (!bgHeight || bgHeight <= 0) {
      return;
    }

    if (ppi) {
      dispatch(utilityAsyncActions.sendAndroidDataCollection({ ppi: ppi }));

      //store ppi in redux
      storeCInfo(ppi, true);

      //go to next setup step
      navigate(
        ROUTING.SETUP_STEPS.url.replace(
          ":tutorialStep",
          (+(tutorialStep || 1) + 1).toString()
        )
      );
    }
  };

  return (
    <div className={`${style.SetupCalibrationRulerPage}`}>
      <LazyVideo
        className={style.backgroundVideo}
        src={window.rxrEnv?.ASSETS_PATH + "/video/background-mobile.mp4"}
        width={"100%"}
        height={"100%"}
        ariaLabel={"background"}
        autoPlay={true}
        muted={true}
        loop={true}
        controls={false}
        playsInline={true}
      />
      <div className={style.borderTop}></div>
      <div
        className={style.borderBottom}
        style={{ top: topval && topval > 0 ? topval : topV + "px" }}
      />
      <div className={style.borderLeft}></div>
      <div
        className={`${style.testBg} ${aniBg ? style.testBgAnimation : ""}`}
        style={{
          height: bgHeight && bgHeight > 0 ? bgHeight + "px" : bgH + "px",
        }}
        onAnimationEnd={() => aniNext()}
      >
        {creditCard && (
          <LazyImage
            className={style.creditCard}
            src={window.rxrEnv?.ASSETS_PATH + "/icons/IDcard-sample.svg"}
            width="auto"
            height="281px"
            alt=""
          />
        )}
        {cardTips && (
          <Tooltip
            className={style.cardTips}
            classNameTail={style.cardTipsTail}
            positionTail="top"
          >
            <span
              dangerouslySetInnerHTML={printHTML(
                translate("setup.ruler_tooltip1")
              )}
            />
          </Tooltip>
        )}
      </div>

      <div className={style.operateArea}>
        <div
          className={style.operateComp}
          style={{
            width: operateCompWidth + "px",
            transform: `translateX(${operateCompTranslateXFinal}px)`,
          }}
        >
          <Button
            className={`${style.btnMinus} ${
              minusShadow ? style.minusShadow : ""
            }`}
            variant="light"
            icon="custom"
            iconInfo={{
              position: "left",
              path:
                window.rxrEnv?.ASSETS_PATH +
                "/icons/calibration-ruler/minus.svg",
              width: "16px",
              height: "3px",
              alt: "-",
            }}
            disabled={!minusShadow}
            style={{ opacity: clickTrigger ? 1 : 0.6, left: xMin + "px" }}
            onClick={() => minusHandle(bgHeight)}
          />

          <div className={style.progress} style={{ width: pWidth + "px" }} />

          <Button
            className={`${style.circleWrapper} ${
              clickTrigger ? style.circleWrapperEnabled : ""
            }`}
            variant="dark"
            icon="custom"
            iconInfo={{
              position: "left",
              path:
                window.rxrEnv?.ASSETS_PATH +
                "/icons/calibration-ruler/slider.svg",
              width: "22px",
              height: "14px",
              alt: "",
            }}
            style={{ left: circleDistance + "px" }}
            onTouchStart={FnStart}
            ref={dragObj}
          >
            {circleTips && (
              <Tooltip
                className={style.circleTips}
                classNameTail={style.circleTipsTail}
              >
                <span
                  dangerouslySetInnerHTML={printHTML(
                    translate("setup.ruler_tooltip2")
                  )}
                />
              </Tooltip>
            )}
          </Button>

          <Button
            className={`${style.btnAdd} ${addShadow ? style.addShadow : ""}`}
            variant="light"
            icon="custom"
            iconInfo={{
              position: "left",
              path:
                window.rxrEnv?.ASSETS_PATH + "/icons/calibration-ruler/add.svg",
              width: "13px",
              height: "13px",
              alt: "+",
            }}
            disabled={!addShadow}
            style={{
              opacity: clickTrigger ? 1 : 0.6,
              left: xMin + scaleWidth + "px",
            }}
            onClick={() => addHandle(bgHeight)}
          />

          <div
            className={style.scale}
            style={{ width: scaleWidth + "px", left: xMin + "px" }}
          >
            <LazyImage
              src={
                window.rxrEnv?.ASSETS_PATH +
                "/icons/calibration-ruler/vertical-lines.svg"
              }
              width="100%"
              height="20px"
              alt=""
              style={{ opacity: clickTrigger ? 1 : 0.6 }}
            />
          </div>
        </div>

        <div className={style.rowButtons}>
          <Button
            // className={""}
            onClick={() => navigate(-1)}
            variant={"light"}
            border={true}
            icon={"leftArrow"}
            analyticsInfo={{
              "data-element-id": "RxRenewal_SetUp-Back",
              "data-description": previousPageLabel,
              "data-analytics_available_call": 0,
            }}
          />
          <Button
            className={style.finishBtn}
            variant="dark"
            onClick={onSubmit}
            disabled={!(bgHeight > 0)}
            analyticsInfo={{
              "data-element-id": "RxRenewal_SetUp-Done",
              "data-description": formatAnalyticsInnerText(
                translate("setup.ruler_cta")
              ),
              "data-analytics_available_call": 1,
            }}
          >
            {translate("setup.ruler_cta")}
          </Button>
        </div>
      </div>

      <ModalDetectDevice />
    </div>
  );
}

export default SetupCalibrationRulerPage;
