import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import { useExternalScript } from "./useExternalScript";
import { useDispatch, useSelector } from "react-redux";
import {
  acuityActions,
  cSizeMapType,
  selectCSVG,
  selectCSize,
  selectCalibratedManually,
  selectNeedManualCalibration,
} from "@slices/acuitySlice";
import {
  getPPIInSession,
  setPPIInSession,
} from "@utility/sessionStorageUtility";

export const useLandoltC = (
  vaValue: number = 10,
  redirectToCalibration: boolean = false
): {
  cSize: string;
  cSVG: string;
  needManualCalibration: "loading" | "true" | "false" | "mini";
  storeCInfo: (ppi?: number, calibratedManuallyParam?: boolean) => void;
  calibratedManually: boolean;
} => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cSize = useSelector((state) => selectCSize(state, vaValue));
  const cSVG = useSelector(selectCSVG);
  const needManualCalibration = useSelector(selectNeedManualCalibration);
  const calibratedManually = useSelector(selectCalibratedManually);

  const [isDeviceLibraryReady, setIsDeviceLibraryReady] = useState(false);
  const [isPatternLibraryReady, setIsPatternLibraryReady] = useState(false);

  //load libraries for device and ppi detection
  useExternalScript(
    window.rxrEnv?.CALIBRATION_LIBRARY_PATH + "/eldevicelib.js",
    () => setIsDeviceLibraryReady(true)
  );
  useExternalScript(
    window.rxrEnv?.CALIBRATION_LIBRARY_PATH + "/elpatternlib.js",
    () => setIsPatternLibraryReady(true)
  );

  useEffect(() => {
    //if the two libraries are loaded
    if (isDeviceLibraryReady && isPatternLibraryReady) {
      if (!cSVG) {
        const savedPPI = getPPIInSession();
        if (!!savedPPI) {
          storeCInfo(savedPPI, false).catch(console.error);
        } else {
          storeCInfo().catch(console.error);
        }
      } else if (needManualCalibration === "true") {
        if (redirectToCalibration) {
          //if ppi are not detectable --> go to calibration phase
          navigate(ROUTING.SETUP_START.url);
        }
      }
    }
  }, [isDeviceLibraryReady, isPatternLibraryReady]);

  useEffect(() => {
    if (
      +cSize.replace("px", "") > 0 &&
      needManualCalibration !== "false" &&
      needManualCalibration !== "mini"
    ) {
      dispatch(
        acuityActions.setNeedManualCalibration({
          needManualCalibration: "false",
        })
      );
    }
  }, [cSize]);

  const storeCInfo = async (
    manualCalibrationPpi?: number,
    calibratedManuallyParam: boolean = false
  ) => {
    if (elpattern && eldevice) {
      let devPPI = 0;
      const devPixelRatio = window.devicePixelRatio;
      const displayDistance = 3048; // by mm - for our the value is fixed and equals to 5 feet or 3048 mm (the distance of the user from the mirror)
      const patternObj = new elpattern.DisplayPattern();
      patternObj.pattern(0); // 0 param means 'C' for the library

      //detect device ppi
      const devParam = await getDeviceParam();
      devPPI = manualCalibrationPpi || devParam.ppi;

      //save ppi also in sessionStorage for persistence
      setPPIInSession(devPPI);

      console.log("Final PPI", devPPI);

      let canBeIphoneMini = false;
      if (!manualCalibrationPpi) {
        [
          "iphone13mini",
          "iphone12mini",
          "iphone11pro",
          "iphonexs",
          "iphonex",
        ].forEach((iphoneModel) => {
          if (devParam.modelName?.includes(iphoneModel)) {
            canBeIphoneMini = true;
          }
        });

        if (!devPPI) {
          //dont' go to the calibration phase but set needManualCalibration=true
          dispatch(
            acuityActions.setNeedManualCalibration({
              needManualCalibration: "true",
            })
          );

          if (redirectToCalibration) {
            //if ppi are not detectable --> go to calibration phase
            navigate(ROUTING.SETUP_START.url);
          }
        } else if (canBeIphoneMini) {
          dispatch(
            acuityActions.setNeedManualCalibration({
              needManualCalibration: "mini",
            })
          );
        } else {
          dispatch(
            acuityActions.setNeedManualCalibration({
              needManualCalibration: "false",
            })
          );
        }
      }

      //get the size of C in all its required variants (10/10, 8/10, 6/10 and 4/10)
      const patternSize10: number = patternObj.patternSizeByPixel(
        devPPI,
        devPixelRatio,
        displayDistance,
        1
      );
      const patternSize8: number = patternObj.patternSizeByPixel(
        devPPI,
        devPixelRatio,
        displayDistance,
        0.8
      );
      const patternSize6: number = patternObj.patternSizeByPixel(
        devPPI,
        devPixelRatio,
        displayDistance,
        0.6
      );
      const patternSize4: number = patternObj.patternSizeByPixel(
        devPPI,
        devPixelRatio,
        displayDistance,
        0.4
      );
      const cSizeMapTemp: cSizeMapType = {
        10: patternSize10,
        8: patternSize8,
        6: patternSize6,
        4: patternSize4,
      };
      dispatch(
        acuityActions.setCSize({
          cSizeMap: cSizeMapTemp,
          calibratedManually: calibratedManuallyParam,
        })
      );

      //get svg for the C
      dispatch(acuityActions.setCSVG({ cSVG: patternObj.pattern(0) }));
      console.log("patternObj.pattern(0)", patternObj.pattern(0));
    } else if (!manualCalibrationPpi) {
      dispatch(
        acuityActions.setNeedManualCalibration({
          needManualCalibration: "true",
        })
      );
    }
  };

  async function getDeviceParam() {
    try {
      const detector = new eldevice.DeviceSpecsService();
      const devType = detector.deviceType(navigator.userAgent);
      await detector.init(devType);
      const devModelInfo = await detector.getDeviceSpecs(
        navigator.userAgent,
        false
      );
      console.log("devModelInfo", devModelInfo);

      let devPPI = 0;
      let devModelName = "";
      if (!devModelInfo) {
        console.warn("No device detected for calibration");
        return { ppi: devPPI, modelName: devModelName };
      } else {
        if (Array.isArray(devModelInfo)) {
          for (const element of devModelInfo) {
            devPPI += element.ppi;
            devModelName += " " + element.modelName;
          }
          devPPI /= devModelInfo.length;
        } else {
          devPPI = devModelInfo.ppi || 0;
          devModelName = devModelInfo.devModelName || "";
        }
      }

      console.log("Calibration detection", {
        ppi: devPPI,
        modelName: devModelName,
      });
      return { ppi: devPPI, modelName: devModelName };
    } catch (e) {
      console.error(e);
      console.warn("No device detected for calibration");
      return { ppi: 0, modelName: "" };
    }
  }

  return {
    cSize: cSize,
    cSVG: cSVG,
    needManualCalibration: needManualCalibration,
    storeCInfo: storeCInfo,
    calibratedManually: calibratedManually,
  };
};
