import HowToReadPrescriptionButton from "@components/HowToReadPrescriptionButton/component";
import ButtonSurvey from "@components/common/ButtonSurvey/component";
import Checkbox from "@components/common/Checkbox/component";
import InfoBanner from "@components/common/InfoBanner/component";
import Input from "@components/common/Input/component";
import ModalSurvey from "@components/common/ModalSurvey/component";
import SelectSimple from "@components/common/SelectSimple/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import { QuestionType } from "@model/SurveyModel";
import { printHTML } from "@utility/htmlUtility";
import { ROUTING } from "@utility/routingUtility";
import React, { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";

type ChipsType = {
  left: string[];
  right: string[];
};

type CLBrandChipsType = {
  right: string;
  left: string;
};

type SelectStateProps = {
  question: QuestionType;
  formData: any;
  prescriptionType: any;
  optional?: boolean;
};
const PrescriptionForm = ({
  question,
  formData,
  prescriptionType,
}: SelectStateProps) => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const breakpoint = useBreakpointBoolean();

  const [
    isHowToReadPrescriptionModalOpen,
    setIsHowToReadPrescriptionModalOpen,
  ] = useState<boolean>(false);

  // const [showPrismValues, setShowPrismValues] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCLModal, setShowCLModal] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [showCLBrandErrors, setShowCLBrandErrors] = useState<boolean>(false);

  const [disableRightAxis, setDisableRightAxis] = useState<boolean>(false);
  const [disableLeftAxis, setDisableLeftAxis] = useState<boolean>(false);
  const [disableAll, setDisableAll] = useState<boolean>(false);

  const [answerChips, setAnswerChips] = useState<ChipsType>();
  const [answerCLBrandChips, setAnswerCLBrandChips] =
    useState<CLBrandChipsType>(null);

  const ids: PrescriptionData = useMemo(() => {
    return getPrescriptionIds(question?.idFE);
  }, [question?.idFE]);

  const CLids: PrescriptionData = useMemo(() => {
    return getCLFormIds(question?.idFE);
  }, [question?.idFE]);

  const [oldFormValues, setOldFormValues] = useState(null);
  useEffect(() => {
    if (showModal) {
      setOldFormValues(formData.getValues());
    } else {
      if (showErrors) {
        //clean showErrors variable - at next modal opening errors will be not shown at once
        setShowErrors(false);
      }
    }
  }, [showModal]);

  useEffect(() => {
    if (!showCLModal) {
      if (showCLBrandErrors) {
        //clean showCLBrandErrors variable - at next modal opening errors will be not shown at once
        setShowCLBrandErrors(false);
      }
    }
  }, [showCLModal]);

  const CLFormids: CLFormData = useMemo(() => {
    return getCLFormIds(question?.idFE);
  }, [question?.idFE]);

  //FORM VALUES
  const rightEye = formData.watch(ids.rightEye);
  const leftEye = formData.watch(ids.leftEye);
  const samePrescription = formData.watch(ids.samePrescription);
  const rightSphereValue = formData.watch(ids.rightSphere);
  const leftSphereValue = formData.watch(ids.leftSphere);
  const showPrismValues = formData.watch(ids.showPrismValues);
  const rightHPrism = formData.watch(ids.rightHPrism);
  const leftHPrism = formData.watch(ids.leftHPrism);
  const rightHPrismBaseDir = formData.watch(ids.rightHPrismBaseDir);
  const leftHPrismBaseDir = formData.watch(ids.leftHPrismBaseDir);
  const rightVPrism = formData.watch(ids.rightVPrism);
  const leftVPrism = formData.watch(ids.leftVPrism);
  const rightVPrismBaseDir = formData.watch(ids.rightVPrismBaseDir);
  const leftVPrismBaseDir = formData.watch(ids.leftVPrismBaseDir);
  const rightCylinder = formData.watch(ids.rightCylinder);
  const leftCylinder = formData.watch(ids.leftCylinder);

  const lensBrandRight = formData.watch(CLFormids.lensBrandRight);
  const lensBrandLeft = formData.watch(CLFormids.lensBrandLeft);
  const sameBrandBothEyes = formData.watch(CLFormids.sameBrandBothEyes);

  useEffect(() => {
    formData.setValue(CLFormids.lensBrandRight, lensBrandRight || "");
  }, [lensBrandRight]);

  useEffect(() => {
    formData.setValue(CLFormids.lensBrandLeft, lensBrandLeft || "");
  }, [lensBrandLeft]);

  useEffect(() => {
    if (!sameBrandBothEyes) {
      formData.setValue(CLFormids.lensBrandLeft, "");
    } else {
      formData.setValue(CLFormids.lensBrandLeft, lensBrandRight);
      formData.clearErrors(CLFormids.lensBrandLeft);
    }

    formData.setValue(CLFormids.sameBrandBothEyes, sameBrandBothEyes);
  }, [sameBrandBothEyes]);

  useEffect(() => {
    //calculate chips at component rendering
    calculateCLBrandChips();

    if (leftSphereValue || rightSphereValue) {
      calculateChips();
    }
  }, []);

  useEffect(() => {
    //re-evaluate form rules after right and left eye checkboxes change
    formData.trigger();
  }, [rightEye, leftEye]);

  useEffect(() => {
    formData.trigger([
      ids.leftBaseCurve,
      ids.leftDiameter,
      ids.leftSphere,
      ids.leftCylinder,
      ids.leftAxis,
      ids.leftAdd,
      ids.leftVPrism,
      ids.leftVPrismBaseDir,
      ids.leftHPrism,
      ids.leftHPrismBaseDir,
    ]);
  }, [samePrescription]);

  const onClose = () => {
    //update chips
    calculateChips();
    calculateCLBrandChips();

    //close modals
    setShowCLModal(false);
    setShowModal(false);
  };

  useEffect(() => {
    if (
      showErrors &&
      Object.keys(formData?.formState?.errors || {}).length === 0
    ) {
      setShowErrors(false);
    }
  }, [JSON.stringify(formData?.formState?.errors || {})]);

  const calculateChips = () => {
    const prescriptionData = {};
    Object.keys(ids).forEach((key) => {
      prescriptionData[key] = formData.getValues(ids[key]);
    });

    const chips = getPrescriptionChips(
      prescriptionData as PrescriptionData,
      translate
    );
    setAnswerChips(chips);
  };

  const calculateCLBrandChips = () => {
    const CLBrandsData = {};
    Object.keys(CLFormids).forEach((key) => {
      CLBrandsData[key] = formData.getValues(CLFormids[key]);
    });

    const chips = getCLBrandChips(CLBrandsData as CLFormData, translate);
    setAnswerCLBrandChips(chips);
  };

  //disable/reset prism values
  useEffect(() => {
    if (!showPrismValues) {
      formData.setValue(ids.rightVPrism, "");
      formData.setValue(ids.leftVPrism, "");
      formData.setValue(ids.rightVPrismBaseDir, "");
      formData.setValue(ids.leftVPrismBaseDir, "");
      formData.setValue(ids.rightHPrism, "");
      formData.setValue(ids.leftHPrism, "");
      formData.setValue(ids.rightHPrismBaseDir, "");
      formData.setValue(ids.leftHPrismBaseDir, "");
      formData.trigger();
    }
  }, [showPrismValues]);

  const [errorInfo, setErrorInfo] = useState<{
    id: string;
    variant: "warning" | "error";
    title: string;
    description: string;
    showReviewType: boolean;
    errorForFields?: string[];
  }>(null);
  const prismValuesRequired =
    showPrismValues &&
    !rightHPrism &&
    !leftHPrism &&
    !rightHPrismBaseDir &&
    !leftHPrismBaseDir &&
    !rightVPrism &&
    !leftVPrism &&
    !rightVPrismBaseDir &&
    !leftVPrismBaseDir;

  useEffect(() => {
    //axis
    const leftAxisErrors = formData?.formState?.errors?.[ids.leftAxis] || {};
    const rightAxisErrors = formData?.formState?.errors?.[ids.rightAxis] || {};
    //prism horizontal
    const leftHPrism = formData?.formState?.errors?.[ids.leftHPrism] || {};
    const rightHPrism = formData?.formState?.errors?.[ids.rightHPrism] || {};
    //prism vertical
    const leftVPrism = formData?.formState?.errors?.[ids.leftVPrism] || {};
    const rightVPrism = formData?.formState?.errors?.[ids.rightVPrism] || {};
    //direction horizontal
    const leftHPrismBaseDir =
      formData?.formState?.errors?.[ids.leftHPrismBaseDir] || {};
    const rightHPrismBaseDir =
      formData?.formState?.errors?.[ids.rightHPrismBaseDir] || {};
    //direction vertical
    const leftVPrismBaseDir =
      formData?.formState?.errors?.[ids.leftVPrismBaseDir] || {};
    const rightVPrismBaseDir =
      formData?.formState?.errors?.[ids.rightVPrismBaseDir] || {};

    if (
      (+rightSphereValue > 0 && +leftSphereValue < 0) ||
      (+rightSphereValue < 0 && +leftSphereValue > 0)
    ) {
      //one sphere negative and one negative
      setErrorInfo({
        id: "sphereWarning",
        variant: "warning",
        title: translate("survey.prescription_warning_sphere_title"),
        description: translate("survey.prescription_warning_sphere_descr"),
        showReviewType: false,
      });
    } else if (showErrors) {
      if (
        [leftAxisErrors?.message, rightAxisErrors?.message].includes(
          "axisRequired"
        )
      ) {
        //axis are required if cylinder is != 0
        setErrorInfo({
          id: "axisRequired",
          variant: "error",
          title: translate("survey.prescription_error_missing_axis_title"),
          description: translate(
            "survey.prescription_error_missing_axis_descr"
          ),
          showReviewType: true,
          errorForFields: [leftAxisErrors, rightAxisErrors]
            .filter((a) => a.message?.includes("axisRequired"))
            .map((a) => a.ref?.name),
        });
      } else if (
        [leftAxisErrors?.message, rightAxisErrors?.message].includes(
          "axisOutOfRange"
        )
      ) {
        //axis are out of range (0 - 180°)
        setErrorInfo({
          id: "axisOutOfRange",
          variant: "error",
          title: translate("survey.prescription_error_wrong_axis_title"),
          description: translate("survey.prescription_error_wrong_axis_descr"),
          showReviewType: true,
          errorForFields: [leftAxisErrors, rightAxisErrors]
            .filter((a) => a.message?.includes("axisOutOfRange"))
            .map((a) => a.ref?.name),
        });
      } else if (
        prismValuesRequired &&
        [
          leftHPrismBaseDir?.type,
          rightHPrismBaseDir?.type,
          leftVPrismBaseDir?.type,
          rightVPrismBaseDir?.type,
          leftHPrism?.type,
          rightHPrism?.type,
          leftVPrism?.type,
          rightVPrism?.type,
        ].includes("required")
      ) {
        //user has selected the prism checkbox but it doesn't inserted some values
        setErrorInfo({
          id: "prismNotInserted",
          variant: "error",
          title: translate(
            "survey.prescription_error_prism_values_required_title"
          ),
          description: "",
          showReviewType: false,
          errorForFields: [
            leftHPrismBaseDir,
            rightHPrismBaseDir,
            leftVPrismBaseDir,
            rightVPrismBaseDir,
            leftHPrism,
            rightHPrism,
            leftVPrism,
            rightVPrism,
          ]
            .filter((a) => a?.type?.includes("required"))
            .map((a) => a.ref?.name),
        });
      } else if (
        [
          leftHPrismBaseDir?.message,
          rightHPrismBaseDir?.message,
          leftVPrismBaseDir?.message,
          rightVPrismBaseDir?.message,
        ].includes("required")
      ) {
        //prism direction is required if user has selected a prism value
        setErrorInfo({
          id: "prismDirectionRequired",
          variant: "error",
          title: translate(
            "survey.prescription_error_base_direction_required_title"
          ),
          description: "",
          showReviewType: false,
          errorForFields: [
            leftHPrismBaseDir,
            rightHPrismBaseDir,
            leftVPrismBaseDir,
            rightVPrismBaseDir,
          ]
            .filter((a) => a.message?.includes("required"))
            .map((a) => a.ref?.name),
        });
      } else if (
        [
          leftHPrism?.message,
          rightHPrism?.message,
          leftVPrism?.message,
          rightVPrism?.message,
        ].includes("required")
      ) {
        //prism value is required if user has selected a prism direction
        setErrorInfo({
          id: "prismValueRequired",
          variant: "error",
          title: translate("survey.prescription_error_prism_required_title"),
          description: "",
          showReviewType: false,
          errorForFields: [leftHPrism, rightHPrism, leftVPrism, rightVPrism]
            .filter((a) => a.message?.includes("required"))
            .map((a) => a.ref?.name),
        });
      } else if (errorInfo) {
        setErrorInfo(null);
      }
    } else if (errorInfo) {
      setErrorInfo(null);
    }
  }, [
    formData?.formState,
    showErrors,
    rightSphereValue,
    leftSphereValue,
    prismValuesRequired,
    JSON.stringify(formData?.formState?.errors || {}),
  ]);

  const errorBanner = (
    <>
      {errorInfo && (
        <InfoBanner
          className={style.errorBanner}
          label={errorInfo.title}
          description={errorInfo.description}
          variant={errorInfo.variant}
          ctaAction={
            errorInfo.showReviewType
              ? () => {
                  navigate(ROUTING.SURVEY.url.replace(":stepId?", "step_4"));
                }
              : undefined
          }
          ctaLabel={translate("survey.review_type_vision_aid")}
        />
      )}
    </>
  );

  return (
    <>
      {prescriptionType.isCL && (
        <ButtonSurvey
          onClick={() => setShowCLModal(true)}
          icon={
            window.rxrEnv?.ASSETS_PATH + "/icons/survey/q3/contact-lenses.svg"
          }
          labelKey="survey.q9_a3_title"
          values={Object.keys(answerCLBrandChips || {})
            .map((key) => answerCLBrandChips[key])
            .filter((value) => value !== null)}
          showOnDesktop={true}
        />
      )}
      {!(answerChips?.left?.length > 0 || answerChips?.right?.length > 0) && (
        <ButtonSurvey
          onClick={() => setShowModal(true)}
          icon={
            window.rxrEnv?.ASSETS_PATH + "/icons/survey/q9/prescription.svg"
          }
          labelKey="survey.q9_a1_title"
          // values={[]}
          showOnDesktop={true}
        />
      )}
      {(answerChips?.right?.length > 0 || answerChips?.left?.length > 0) && (
        <div className={style.prescriptionButtonsContainer}>
          {answerChips?.right?.length > 0 && (
            <ButtonSurvey
              onClick={() => setShowModal(true)}
              icon={
                window.rxrEnv?.ASSETS_PATH + "/icons/survey/q9/prescription.svg"
              }
              labelKey="survey.questiongroup_prescription_values_OD"
              values={answerChips.right}
              showOnDesktop={true}
            />
          )}
          {answerChips?.left?.length > 0 && (
            <ButtonSurvey
              onClick={() => setShowModal(true)}
              icon={
                window.rxrEnv?.ASSETS_PATH + "/icons/survey/q9/prescription.svg"
              }
              labelKey="survey.questiongroup_prescription_values_OS"
              values={answerChips.left}
              showOnDesktop={true}
            />
          )}
        </div>
      )}
      {prescriptionType.isCL && (
        <ModalSurvey
          id="CL"
          hide={!showCLModal}
          className={`${style.CLModalContainer}`}
          isOpen={true}
          htmlTitle={translate("survey.q9_a3_title")}
          onClose={() => {
            onClose();
            if (!showCLBrandErrors) {
              setShowCLBrandErrors(true);
            }
          }}
          icon={
            window.rxrEnv?.ASSETS_PATH + "/icons/survey/q3/contact-lenses.svg"
          }
          footerCtaAction={async () => {
            //check if there are some errors about CL modal
            let formCLValid = true;
            let firstIdError = "";
            await formData.trigger();
            Object.keys(CLFormids).forEach((key) => {
              if (formData?.formState?.errors?.[CLFormids[key]]) {
                formCLValid = false;
                if (!firstIdError) {
                  firstIdError = CLFormids[key];
                }
              }
            });

            if (firstIdError) {
              document.getElementById(firstIdError)?.focus();
            }

            if (formCLValid) {
              onClose();
            } else if (!showCLBrandErrors) {
              setShowCLBrandErrors(true);
            }
          }}
          footerCtaLabel={translate("survey.confirm")}
          footerCtaDisabled={false}
        >
          <div className={style.CLModalContent}>
            <div
              className={style.ClBrandDescription}
              dangerouslySetInnerHTML={printHTML(
                translate("survey.q9_cl_brand_and_model_description")
              )}
            />

            <Controller
              control={formData.control}
              name={CLFormids.sameBrandBothEyes}
              render={({ field }) => (
                <Checkbox
                  checked={!!field.value}
                  onChange={(value) => field.onChange(value)}
                  id={"samebrand_botheyes_checkbox"}
                  name={"samebrand_botheyes_checkbox"}
                >
                  {translate("survey.brand_same_both_eyes")}
                </Checkbox>
              )}
            />
            <CLInputComponent
              className={style.CLBrandInput}
              formData={formData}
              ids={CLFormids}
              sameForBothEyes={sameBrandBothEyes}
              showCLBrandErrors={showCLBrandErrors}
              placeholder={translate("survey.q9_CL_brand")}
              internalPlaceholder={translate("survey.q9_CL_brand_placeholder")}
              ariaErrormessage={translate("survey.q9_CL_brand_error")}
            />
          </div>
        </ModalSurvey>
      )}

      <ModalSurvey
        id="GL"
        isOpen={true}
        hide={!showModal}
        className={`prescription-modal`}
        htmlTitle={translate("survey.q9_a1_title")}
        key={JSON.stringify(oldFormValues || {}) + showModal?.toString()} //force re-render to clean data. Use showModal too because of X button that cleans the form
        onClose={() => {
          if (oldFormValues) {
            //restore old prescription values
            Object.values(ids)?.forEach((key) => {
              if (
                oldFormValues[key] === null ||
                oldFormValues[key] === undefined
              ) {
                formData.unregister(key);
              } else {
                formData.setValue(key, oldFormValues[key]);
              }
            });
          }
          onClose();
        }}
        icon={window.rxrEnv?.ASSETS_PATH + "/icons/survey/q9/prescription.svg"}
        footerCtaAction={() => {
          // Check if there are some errors about prescription
          let formPrescriptionValid = true;

          // Fix eventual axis errors on symbol ° (sometimes it's not at the end of value like 18°0 instead of 180°)
          const formDataValues = formData.getValues();
          const leftAxis = formDataValues[ids.leftAxis];
          const rightAxis = formDataValues[ids.rightAxis];

          if (leftAxis) {
            formData.setValue(ids.leftAxis, leftAxis.replace(/°/g, "") + "°");
          }
          if (rightAxis) {
            formData.setValue(ids.rightAxis, rightAxis.replace(/°/g, "") + "°");
          }

          formData.trigger();
          setTimeout(() => {
            // Wait for trigger to update formData
            let firstIdError = "";
            Object.keys(ids).forEach((key) => {
              const id = ids[key];

              // do not consider fields in CLids (they're in another modal)
              if (
                !Object.values(CLids).includes(id) &&
                formData?.formState?.errors?.[id]
              ) {
                formPrescriptionValid = false;
                if (!firstIdError) {
                  firstIdError = id;
                }
              }
            });

            if (firstIdError) {
              document.getElementById(firstIdError)?.focus();
            }

            if (formPrescriptionValid) {
              onClose();
            } else if (!showErrors) {
              setShowErrors(true);
            }
          }, 0);
        }}
        footerCtaLabel={translate("survey.confirm")}
        footerCtaDisabled={false}
        footerTopElement={errorBanner}
        footerBottomElement={
          <HowToReadPrescriptionButton
            className={style.howToReadPrescriptionLink}
            isCL={prescriptionType.isCL}
            modalSelector={
              breakpoint.isDesktopOrTabletLandscape
                ? ".prescription-modal:not(." + style.none + ") > div"
                : ""
            }
            insideModal={breakpoint.isDesktopOrTabletLandscape}
            onChangeShowModal={(showModal) => {
              setDisableAll(showModal);
              setIsHowToReadPrescriptionModalOpen(showModal);
            }}
          />
        }
        triggerShadow={showPrismValues}
        isNestedModalOpen={isHowToReadPrescriptionModalOpen}
      >
        <div className={style.SelectPrescriptionContainer}>
          <div className={`${style.SelectPrescriptionController}`}>
            <Controller
              control={formData.control}
              name={ids.samePrescription}
              render={({ field }) => (
                <Checkbox
                  checked={!!field.value}
                  onChange={(value) => field.onChange(value)}
                  id={"sameprescription_checkbox"}
                  name={"sameprescription_checkbox"}
                  className={style.samePrescriptionCheckbox}
                  disabled={disableAll}
                >
                  {translate("survey.prescription_same_both_eyes")}
                </Checkbox>
              )}
            />

            {/*EYES CHECKBOXES - ONLY CL */}
            {!samePrescription && (
              <>
                <div
                  className={`${style.LabelsCol} ${
                    prescriptionType.isEyeglasses ? "" : style.none
                  }`}
                >
                  <div className={`${style.Label} ${style.eyeSelectionLabel}`}>
                    {translate("survey.right")}
                  </div>
                  <div className={`${style.Label} ${style.eyeSelectionLabel}`}>
                    {translate("survey.left")}
                  </div>
                </div>
                <div
                  className={`${style.LabelsCol} ${
                    prescriptionType.isCL ? "" : style.none
                  }`}
                >
                  <CheckboxEyeComponent
                    formData={formData}
                    id={ids.rightEye}
                    oppositeCheckboxId={ids.leftEye}
                    label={translate("survey.right")}
                    showCheckbox={true}
                    disabled={disableAll}
                  />
                  <CheckboxEyeComponent
                    formData={formData}
                    id={ids.leftEye}
                    oppositeCheckboxId={ids.rightEye}
                    label={translate("survey.left")}
                    showCheckbox={true}
                    disabled={disableAll}
                  />
                </div>
              </>
            )}

            {/* BASE CURVE - ONLY CL */}
            {prescriptionType.isCL && (
              <>
                <div className={style.inputLabel}>
                  {translate("survey.base_curve")}
                </div>
                <div className={style.InputCol}>
                  <BaseCurveComponent
                    formData={formData}
                    id={ids.rightBaseCurve}
                    showErrors={showErrors}
                    disabled={!rightEye || disableAll}
                    translate={translate}
                    errorInfo={errorInfo}
                  />
                  {!samePrescription && (
                    <BaseCurveComponent
                      formData={formData}
                      id={ids.leftBaseCurve}
                      showErrors={showErrors}
                      disabled={!leftEye || disableAll}
                      translate={translate}
                      errorInfo={errorInfo}
                    />
                  )}
                </div>
                {/* DIAMETER - ONLY CL */}
                <div className={style.inputLabel}>
                  {translate("survey.diameter")}
                </div>
                <div className={style.InputCol}>
                  <DiameterComponent
                    formData={formData}
                    id={ids.rightDiameter}
                    showErrors={showErrors}
                    disabled={!rightEye || disableAll}
                    translate={translate}
                    errorInfo={errorInfo}
                  />
                  {!samePrescription && (
                    <DiameterComponent
                      formData={formData}
                      id={ids.leftDiameter}
                      showErrors={showErrors}
                      disabled={!leftEye || disableAll}
                      translate={translate}
                      errorInfo={errorInfo}
                    />
                  )}
                </div>
              </>
            )}

            {/* POWER (SPHERE) - CL AND GL */}
            <div className={style.inputLabel}>
              {translate("survey.power_sphere")}
            </div>
            <div className={style.InputCol}>
              <SphereComponent
                formData={formData}
                id={ids.rightSphere}
                showErrors={showErrors}
                showSphereWarning={errorInfo?.id === "sphereWarning"}
                disabled={!rightEye || disableAll}
                translate={translate}
                errorInfo={errorInfo}
              />
              {!samePrescription && (
                <SphereComponent
                  formData={formData}
                  id={ids.leftSphere}
                  showErrors={showErrors}
                  showSphereWarning={errorInfo?.id === "sphereWarning"}
                  disabled={!leftEye || disableAll}
                  translate={translate}
                  errorInfo={errorInfo}
                />
              )}
            </div>

            {/* CYLINDER - CL AND GL */}
            <div className={style.inputLabel}>
              {translate("survey.cylinder")}
            </div>
            <div className={style.InputCol}>
              <CylinderComponent
                formData={formData}
                id={ids.rightCylinder}
                idAxis={ids.rightAxis}
                disabled={!rightEye || disableAll}
                translate={translate}
              />
              {!samePrescription && (
                <CylinderComponent
                  formData={formData}
                  id={ids.leftCylinder}
                  idAxis={ids.leftAxis}
                  disabled={!leftEye || disableAll}
                  translate={translate}
                />
              )}
            </div>

            {/* AXIS - CL AND GL */}
            <div className={style.inputLabel}>{translate("survey.axis")}</div>
            <div className={style.InputCol}>
              <AxisComponent
                formData={formData}
                id={ids.rightAxis}
                idCylinder={ids.rightCylinder}
                valueCylinder={rightCylinder}
                disabled={disableRightAxis || !rightEye || disableAll}
                showErrors={showErrors}
                translate={translate}
                errorInfo={errorInfo}
              />
              {!samePrescription && (
                <AxisComponent
                  formData={formData}
                  id={ids.leftAxis}
                  idCylinder={ids.leftCylinder}
                  valueCylinder={leftCylinder}
                  disabled={disableLeftAxis || !leftEye || disableAll}
                  showErrors={showErrors}
                  translate={translate}
                  errorInfo={errorInfo}
                />
              )}
            </div>

            {/* ADD - CL (free input text) AND GL (select from 0.5-4.0 with 0.25 steps) */}
            {prescriptionType.isADDPresent && (
              <>
                <div className={style.inputLabel}>
                  {translate("survey.add")}
                </div>
                <div className={style.InputCol}>
                  <AdditionalComponent
                    formData={formData}
                    id={ids.rightAdd}
                    isGlasses={prescriptionType.isEyeglasses}
                    translate={translate}
                    disabled={!rightEye || disableAll}
                  />
                  {!samePrescription && (
                    <AdditionalComponent
                      formData={formData}
                      id={ids.leftAdd}
                      isGlasses={prescriptionType.isEyeglasses}
                      translate={translate}
                      disabled={!leftEye || disableAll}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {/* {showErrors && retrieveErrors("main").length > 0 && (
            <div className={style.ErrorContentDiv}>
              {retrieveErrors("main")[0]?.message}
            </div>
          )} */}

          {/* SHOW PRISM VALUES CHECKBOX - ONLY GL */}
          {prescriptionType.isEyeglasses && (
            <Controller
              control={formData.control}
              name={ids.showPrismValues}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    // setTimeout(() => {
                    //   formData.trigger();
                    // }, 0);
                  }}
                  id={"showPrismValues_checkbox"}
                  name={"showPrismValues_checkbox"}
                  className={style.PrismValuesCheckbox}
                  disabled={disableAll}
                >
                  {translate("survey.prism_values_checkbox_label")}
                </Checkbox>
              )}
            />
          )}

          {/* PRISM VALUES - ONLY GL */}
          {showPrismValues && (
            <>
              <div className={`${style.SelectPrescriptionController}`}>
                {!samePrescription && (
                  <div className={style.LabelsCol}>
                    <div
                      className={`${style.Label} ${style.eyeSelectionLabel}`}
                    >
                      {translate("survey.right")}
                    </div>
                    <div
                      className={`${style.Label} ${style.eyeSelectionLabel}`}
                    >
                      {translate("survey.left")}
                    </div>
                  </div>
                )}

                <div className={style.inputLabel}>
                  {translate("survey.prism_horizontal")}
                </div>
                <div className={style.InputCol}>
                  <HorizontalVerticalComponent
                    formData={formData}
                    id={ids.rightHPrism}
                    idBaseDir={ids.rightHPrismBaseDir}
                    prismDirection="prism_h"
                    showErrors={showErrors}
                    required={prismValuesRequired}
                    disabled={!rightEye || disableAll}
                    translate={translate}
                    errorInfo={errorInfo}
                  />
                  {!samePrescription && (
                    <HorizontalVerticalComponent
                      formData={formData}
                      id={ids.leftHPrism}
                      idBaseDir={ids.leftHPrismBaseDir}
                      prismDirection="prism_h"
                      showErrors={showErrors}
                      required={prismValuesRequired}
                      disabled={!leftEye || disableAll}
                      translate={translate}
                      errorInfo={errorInfo}
                    />
                  )}
                </div>

                <div className={style.inputLabel}>
                  {translate("survey.prism_basedir")}
                </div>
                <div className={style.InputCol}>
                  <DirectionComponent
                    formData={formData}
                    id={ids.rightHPrismBaseDir}
                    idHorizontalVertical={ids.rightHPrism}
                    valueHorizontalVertical={rightHPrism}
                    prismDirection="prism_h_basedir"
                    showErrors={showErrors}
                    required={prismValuesRequired}
                    disabled={!rightEye || disableAll}
                    translate={translate}
                    errorInfo={errorInfo}
                  />
                  {!samePrescription && (
                    <DirectionComponent
                      formData={formData}
                      id={ids.leftHPrismBaseDir}
                      idHorizontalVertical={ids.leftHPrism}
                      valueHorizontalVertical={leftHPrism}
                      prismDirection="prism_h_basedir"
                      showErrors={showErrors}
                      required={prismValuesRequired}
                      disabled={!leftEye || disableAll}
                      translate={translate}
                      errorInfo={errorInfo}
                    />
                  )}
                </div>
                <div className={style.inputLabel}>
                  {translate("survey.prism_vertical")}
                </div>
                <div className={style.InputCol}>
                  <HorizontalVerticalComponent
                    formData={formData}
                    id={ids.rightVPrism}
                    idBaseDir={ids.rightVPrismBaseDir}
                    prismDirection="prism_v"
                    showErrors={showErrors}
                    required={prismValuesRequired}
                    disabled={!rightEye || disableAll}
                    translate={translate}
                    errorInfo={errorInfo}
                  />
                  {!samePrescription && (
                    <HorizontalVerticalComponent
                      formData={formData}
                      id={ids.leftVPrism}
                      idBaseDir={ids.leftVPrismBaseDir}
                      prismDirection="prism_v"
                      showErrors={showErrors}
                      required={prismValuesRequired}
                      disabled={!leftEye || disableAll}
                      translate={translate}
                      errorInfo={errorInfo}
                    />
                  )}
                </div>

                <div className={style.inputLabel}>
                  {translate("survey.prism_basedir")}
                </div>
                <div className={style.InputCol}>
                  <DirectionComponent
                    formData={formData}
                    id={ids.rightVPrismBaseDir}
                    idHorizontalVertical={ids.rightVPrism}
                    valueHorizontalVertical={rightVPrism}
                    prismDirection="prism_v_basedir"
                    showErrors={showErrors}
                    required={prismValuesRequired}
                    disabled={!rightEye || disableAll}
                    translate={translate}
                    errorInfo={errorInfo}
                  />
                  {!samePrescription && (
                    <DirectionComponent
                      formData={formData}
                      id={ids.leftVPrismBaseDir}
                      idHorizontalVertical={ids.leftVPrism}
                      valueHorizontalVertical={leftVPrism}
                      prismDirection="prism_v_basedir"
                      showErrors={showErrors}
                      required={prismValuesRequired}
                      disabled={!leftEye || disableAll}
                      translate={translate}
                      errorInfo={errorInfo}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </ModalSurvey>
    </>
  );
};

export type PrescriptionData = {
  lensBrandRight: string;
  lensBrandLeft: string;
  samePrescription?: string;
  sameBrandBothEyes?: string;
  rightEye?: string;
  leftEye?: string;
  rightBaseCurve: string;
  leftBaseCurve: string;
  rightDiameter: string;
  leftDiameter: string;
  rightSphere: string;
  leftSphere: string;
  rightCylinder: string;
  leftCylinder: string;
  rightAxis: string;
  leftAxis: string;
  rightAdd: string;
  leftAdd: string;
  showPrismValues: string;
  rightVPrism: string;
  leftVPrism: string;
  rightVPrismBaseDir: string;
  leftVPrismBaseDir: string;
  rightHPrism: string;
  leftHPrism: string;
  rightHPrismBaseDir: string;
  leftHPrismBaseDir: string;
};

export type CLFormData = {
  lensBrandRight: string;
  lensBrandLeft: string;
  sameBrandBothEyes: string;
};
export const getPrescriptionIds = (id: string): PrescriptionData => {
  return {
    samePrescription: id + "_sameprescription",
    lensBrandRight: id + "_lensBrandRight",
    lensBrandLeft: id + "_lensBrandLeft",
    sameBrandBothEyes: id + "_sameBrandBothEyes",
    rightEye: id + "_rightEye",
    leftEye: id + "_leftEye",
    rightBaseCurve: id + "_rightBaseCurve",
    leftBaseCurve: id + "_leftBaseCurve",
    rightDiameter: id + "_rightDiameter",
    leftDiameter: id + "_leftDiameter",
    rightSphere: id + "_rightSphere",
    leftSphere: id + "_leftSphere",
    rightCylinder: id + "_rightCylinder",
    leftCylinder: id + "_leftCylinder",
    rightAxis: id + "_rightAxis",
    leftAxis: id + "_leftAxis",
    rightAdd: id + "_rightAdd",
    leftAdd: id + "_leftAdd",
    showPrismValues: id + "_showPrismValues",
    rightVPrism: id + "_rightVPrism",
    leftVPrism: id + "_leftVPrism",
    rightVPrismBaseDir: id + "_rightVPrismBaseDir",
    leftVPrismBaseDir: id + "_leftVPrismBaseDir",
    rightHPrism: id + "_rightHPrism",
    leftHPrism: id + "_leftHPrism",
    rightHPrismBaseDir: id + "_rightHPrismBaseDir",
    leftHPrismBaseDir: id + "_leftHPrismBaseDir",
  };
};
export const getCLFormIds = (id: string): CLFormData => {
  return {
    lensBrandRight: id + "_lensBrandRight",
    lensBrandLeft: id + "_lensBrandLeft",
    sameBrandBothEyes: id + "_sameBrandBothEyes",
  };
};

export const sanitizePrescriptionValues = (
  data: PrescriptionData
): PrescriptionData => {
  if (!data) {
    return data;
  }

  const sanitizedData = { ...data };

  const samePrescription = data.samePrescription;
  const rightEye = data.rightEye;
  const leftEye = data.leftEye;

  Object.keys(data).forEach((key) => {
    let value = data[key];

    if (!rightEye) {
      //if right eye is disabled --> ignore right eye
      switch (key) {
        case "rightBaseCurve":
        case "rightDiameter":
        case "rightSphere":
        case "rightCylinder":
        case "rightAxis":
        case "rightAdd":
        case "rightVPrism":
        case "rightVPrismBaseDir":
        case "rightHPrism":
        case "rightHPrismBaseDir":
          value = "";
          break;
      }
    }
    if (!leftEye) {
      //if left eye is disabled --> ignore left eye
      switch (key) {
        case "leftBaseCurve":
        case "leftDiameter":
        case "leftSphere":
        case "leftCylinder":
        case "leftAxis":
        case "leftAdd":
        case "leftVPrism":
        case "leftVPrismBaseDir":
        case "leftHPrism":
        case "leftHPrismBaseDir":
          value = "";
          break;
      }
    }

    if (samePrescription) {
      //handle same prescription --> left values get the right values
      switch (key) {
        case "leftBaseCurve":
          value = data.rightBaseCurve;
          break;
        case "leftDiameter":
          value = data.rightDiameter;
          break;
        case "leftSphere":
          value = data.rightSphere;
          break;
        case "leftCylinder":
          value = data.rightCylinder;
          break;
        case "leftAxis":
          value = data.rightAxis;
          break;
        case "leftAdd":
          value = data.rightAdd;
          break;
        case "leftVPrism":
          value = data.rightVPrism;
          break;
        case "leftVPrismBaseDir":
          value = data.rightVPrismBaseDir;
          break;
        case "leftHPrism":
          value = data.rightHPrism;
          break;
        case "leftHPrismBaseDir":
          value = data.rightHPrismBaseDir;
          break;
      }
    }

    sanitizedData[key] = value;
  });

  return sanitizedData;
};

export const getCLBrandChips = (
  data: CLFormData,
  translate: (key: string) => string
): CLBrandChipsType => {
  const createCLChipLabel = (key: string, value: string) => {
    return {
      label: translate(key),
      value: value,
    };
  };

  return {
    right: data.lensBrandRight
      ? data.sameBrandBothEyes
        ? data.lensBrandRight
        : createCLChipLabel("survey.right_eye", data.lensBrandRight)
      : null,
    left:
      !data.sameBrandBothEyes && data.lensBrandLeft
        ? createCLChipLabel("survey.left_eye", data.lensBrandLeft)
        : null,
  };
};

export const getPrescriptionChips = (
  data: PrescriptionData,
  translate: (key: string) => string
): ChipsType => {
  const rightChips = [];
  const leftChips = [];

  const createChipLabel = (key: string, value: string) => {
    return translate(key) + ": " + value;
  };

  if (data) {
    data = sanitizePrescriptionValues(data);

    Object.keys(data).forEach((key) => {
      let value = data[key];

      if (value) {
        let label = "";
        //get label
        switch (key) {
          case "rightBaseCurve":
          case "leftBaseCurve":
            label = createChipLabel("survey.base_curve", value);
            break;
          case "rightDiameter":
          case "leftDiameter":
            label = createChipLabel("survey.diameter", value);
            break;
          case "rightSphere":
          case "leftSphere":
            label = createChipLabel("survey.power_sphere", value);
            break;
          case "rightCylinder":
          case "leftCylinder":
            label = createChipLabel("survey.cylinder", value);
            break;
          case "rightAxis":
          case "leftAxis":
            label = createChipLabel("survey.axis", value);
            break;
          case "rightAdd":
          case "leftAdd":
            label = createChipLabel("survey.add", value);
            break;
          case "rightVPrism":
          case "leftVPrism":
            if (data.showPrismValues) {
              label = createChipLabel("survey.prism_vertical", value);
            }
            break;
          case "rightVPrismBaseDir":
          case "leftVPrismBaseDir":
            if (data.showPrismValues) {
              label = createChipLabel("survey.prism_basedir", value);
            }
            break;
          case "rightHPrism":
          case "leftHPrism":
            if (data.showPrismValues) {
              label = createChipLabel("survey.prism_horizontal", value);
            }
            break;
          case "rightHPrismBaseDir":
          case "leftHPrismBaseDir":
            if (data.showPrismValues) {
              label = createChipLabel("survey.prism_basedir", value);
            }
            break;
        }

        if (label) {
          //insert in the left or right eye
          switch (key) {
            case "rightBaseCurve":
            case "rightDiameter":
            case "rightSphere":
            case "rightCylinder":
            case "rightAxis":
            case "rightAdd":
            case "rightVPrism":
            case "rightVPrismBaseDir":
            case "rightHPrism":
            case "rightHPrismBaseDir":
              rightChips.push(label);
              break;
            case "leftBaseCurve":
            case "leftDiameter":
            case "leftSphere":
            case "leftCylinder":
            case "leftAxis":
            case "leftAdd":
            case "leftVPrism":
            case "leftVPrismBaseDir":
            case "leftHPrism":
            case "leftHPrismBaseDir":
              leftChips.push(label);
              break;
          }
        }
      }
    });
  }

  return {
    left: leftChips,
    right: rightChips,
  };
};

// define values of all prescription fields
const generateInputValues = (field: string, translate?) => {
  const values = [];
  var currentValue;
  switch (field) {
    case "basecurve":
      currentValue = 7;
      while (currentValue <= 10) {
        values.push(currentValue.toFixed(1));
        currentValue += 0.1;
      }
      break;
    case "diameter":
      currentValue = 14;
      while (currentValue <= 14.5) {
        values.push(currentValue.toFixed(1));
        currentValue += 0.1;
      }
      break;
    case "sphere":
    case "cyl":
      currentValue = -20;
      while (currentValue <= 20) {
        values.push(currentValue.toFixed(2));
        currentValue += 0.25;
      }
      break;
    case "add":
    case "add-cl":
      if (field === "add-cl" && translate) {
        values.push(translate("survey.add_low"));
        values.push(translate("survey.add_medium"));
        values.push(translate("survey.add_high"));
      }

      currentValue = 0.5;
      while (currentValue <= 4) {
        values.push(currentValue.toFixed(2));
        currentValue += 0.25;
      }
      break;
    case "prism_v":
    case "prism_h":
      currentValue = 0.5;
      while (currentValue <= 5) {
        values.push(currentValue.toFixed(1));
        currentValue += 0.5;
      }
      break;
    case "prism_v_basedir":
    case "prism_h_basedir":
      values.push("Up");
      values.push("Down");
      values.push("In");
      values.push("Out");
      break;
  }
  return values;
};

const CheckboxEyeComponent = ({
  formData,
  id,
  oppositeCheckboxId,
  label,
  showCheckbox = false,
  disabled = false,
}) => {
  return showCheckbox ? (
    <Controller
      control={formData.control}
      name={id}
      defaultValue={true}
      render={({ field }) => (
        <Checkbox
          checked={field.value}
          onChange={(value) => {
            if (
              !value?.target.checked &&
              !formData.getValues(oppositeCheckboxId)
            ) {
              //non possono esserci tutti e due le checkbox a false
              formData.setValue(oppositeCheckboxId, true);
            }
            field.onChange(value);
          }}
          id={id}
          name={id}
          className={`${style.Label} ${style.eyeSelectionLabel}`}
          disabled={disabled}
        >
          {label}
        </Checkbox>
      )}
    />
  ) : (
    <div className={`${style.Label} ${style.eyeSelectionLabel}`}>{label}</div>
  );
};

const BaseCurveComponent = ({
  formData,
  id,
  showErrors = false,
  disabled,
  translate,
  errorInfo,
}) => {
  const options = useMemo(() => {
    return generateInputValues("basecurve").map((answer) => ({
      value: answer.toString(),
      title: answer.toString(),
    }));
  }, []);

  return (
    <Controller
      name={id}
      control={formData.control}
      defaultValue={""}
      rules={{
        validate: (value) => {
          if (!disabled) {
            if (!value || value === "") {
              return "required";
            }
          }
          return true;
        },
      }}
      render={({ field, fieldState }) => (
        <SelectSimple
          id={id}
          onChange={(value, isDefault) => {
            field.onChange(value);
          }}
          className={style.InputField}
          ariaLabel={`${translate("survey.base_curve")} - required field${
            errorInfo?.errorForFields?.includes(id)
              ? ` - ${errorInfo.title}`
              : ""
          }`}
          defaultValue={""}
          options={options}
          selection={field.value}
          isError={fieldState.error && showErrors}
          disabled={disabled}
          middleValueMenu={options?.[0]?.value}
          placeholder={translate("survey.placeholder")}
          keepPlaceholder={true}
          ariaErrormessage={"Please fill this field"}
        />
      )}
    />
  );
};

const DiameterComponent = ({
  formData,
  id,
  showErrors = false,
  disabled,
  translate,
  errorInfo,
}) => {
  const options = useMemo(() => {
    return generateInputValues("diameter").map((answer) => ({
      value: answer.toString(),
      title: answer.toString(),
    }));
  }, []);

  return (
    <Controller
      name={id}
      control={formData.control}
      defaultValue={""}
      rules={{
        validate: (value) => {
          if (!disabled) {
            if (!value || value === "") {
              return "required";
            }
          }
          return true;
        },
      }}
      render={({ field, fieldState }) => (
        <SelectSimple
          id={id}
          onChange={(value, isDefault) => {
            field.onChange(value);
            // formData.trigger();
          }}
          ariaLabel={`${translate("survey.diameter")}${
            !disabled ? " - required field" : ""
          }${
            errorInfo?.errorForFields?.includes(id)
              ? ` - ${errorInfo.title}`
              : ""
          }`}
          className={style.InputField}
          defaultValue={""}
          options={options}
          selection={field.value}
          isError={fieldState.error && showErrors}
          disabled={disabled}
          middleValueMenu={options?.[0]?.value}
          placeholder={translate("survey.placeholder")}
          keepPlaceholder={true}
          ariaErrormessage={"Please fill this field"}
        />
      )}
    />
  );
};

const SphereComponent = ({
  formData,
  id,
  showErrors = false,
  showSphereWarning = false,
  disabled,
  translate,
  errorInfo,
}) => {
  const options = useMemo(() => {
    return generateInputValues("sphere").map((answer) => ({
      value: answer.toString(),
      title: answer.toString(),
    }));
  }, []);

  return (
    <Controller
      name={id}
      control={formData.control}
      defaultValue={""}
      rules={{
        validate: (value) => {
          if (!disabled) {
            if (!value || value === "") {
              return "required";
            }
          }
          return true;
        },
      }}
      render={({ field, fieldState }) => (
        <SelectSimple
          id={id}
          onChange={(value, isDefault) => {
            field.onChange(value);
          }}
          ariaLabel={`${translate("survey.power_sphere")}${
            !disabled ? " - required field" : ""
          }${
            errorInfo?.errorForFields?.includes(id)
              ? ` - ${errorInfo.title}`
              : ""
          }`}
          className={style.InputField}
          defaultValue={""}
          options={options}
          selection={field.value}
          isError={fieldState.error && showErrors}
          isWarning={showSphereWarning}
          disabled={disabled}
          middleValueMenu="0.00"
          placeholder={translate("survey.placeholder")}
          keepPlaceholder={true}
          ariaErrormessage={"Please fill this field"}
        />
      )}
    />
  );
};

const CylinderComponent = ({ formData, id, idAxis, disabled, translate }) => {
  const options = useMemo(() => {
    return generateInputValues("cyl").map((answer) => ({
      value: answer.toString(),
      title: answer.toString(),
    }));
  }, []);

  return (
    <Controller
      name={id}
      control={formData.control}
      defaultValue={""}
      rules={{}}
      render={({ field }) => (
        <SelectSimple
          id={id}
          onChange={(value, isDefault) => {
            field.onChange(value);
            if (value === "") {
              formData.setValue(idAxis, null);
            }
          }}
          ariaLabel={translate("survey.cylinder")}
          className={style.InputField}
          defaultValue={""}
          options={options}
          selection={field.value}
          disabled={disabled}
          middleValueMenu="0.00"
          placeholder={translate("survey.placeholder")}
          keepPlaceholder={true}
          ariaErrormessage={"Please fill this field"}
        />
      )}
    />
  );
};

const AxisComponent = ({
  formData,
  id,
  idCylinder,
  valueCylinder,
  disabled = false,
  showErrors = false,
  translate,
  errorInfo,
}) => {
  return (
    <Controller
      name={id}
      control={formData.control}
      defaultValue={""}
      rules={{
        validate: (value, allValues) => {
          if (!disabled) {
            const numberValue = +value?.replace(/°/g, "");

            if (value === "" && !["", "0.00"].includes(allValues[idCylinder])) {
              return "axisRequired";
            }
            if (value && (numberValue > 180 || numberValue < 0)) {
              return "axisOutOfRange";
            }
            if (value && !Number.isInteger(numberValue)) {
              return "numberNotValid";
            }
          }
          return true;
        },
      }}
      render={({ field, fieldState }) => {
        const getMask = (value) => {
          value = value?.replace(/°/g, "") || "";

          switch (value?.length) {
            case 1:
              return "9°9";
            case 2:
              return "99°9";
            default:
              return "999°9";
          }
        };

        return (
          <InputMask
            mask={getMask(field.value)}
            maskChar={null}
            value={field.value}
            onChange={field.onChange}
            disabled={disabled}
          >
            {(inputProps) => (
              <Input
                id={id}
                {...inputProps}
                ariaLabel={`${translate("survey.axis")}${
                  !["", "0.00"].includes(valueCylinder) && !disabled
                    ? " - required field"
                    : ""
                }${
                  errorInfo?.errorForFields?.includes(id)
                    ? ` - ${errorInfo.title}`
                    : ""
                }`}
                disabled={disabled}
                className={style.InputField}
                isError={fieldState.error && showErrors}
                type="text"
                maxLength={4}
                pattern="[\d]*"
                inputMode="numeric"
                ariaErrormessage={"Please fill this field"}
              />
            )}
          </InputMask>
        );
      }}
    />
  );
};

const AdditionalComponent = ({
  formData,
  id,
  isGlasses = true,
  translate,
  disabled,
}) => {
  const options = useMemo(() => {
    return generateInputValues(isGlasses ? "add" : "add-cl", translate).map(
      (answer) => ({
        value: answer.toString(),
        title: answer.toString(),
      })
    );
  }, [isGlasses, translate]);

  return (
    <Controller
      name={id}
      control={formData.control}
      defaultValue={""}
      render={({ field }) => (
        <SelectSimple
          id={id}
          onChange={(value, isDefault) => {
            field.onChange(value);
          }}
          ariaLabel={translate("survey.add")}
          className={style.InputField}
          defaultValue={""}
          options={options}
          selection={field.value}
          disabled={disabled}
          middleValueMenu={options?.[0]?.value}
          placeholder={translate("survey.placeholder")}
          keepPlaceholder={true}
          ariaErrormessage={"Please fill this field"}
        />
      )}
    />
  );
};

const DirectionComponent = ({
  formData,
  id,
  idHorizontalVertical,
  valueHorizontalVertical,
  prismDirection,
  showErrors = false,
  required = false,
  disabled,
  translate,
  errorInfo,
}) => {
  const options = useMemo(() => {
    return generateInputValues(prismDirection).map((answer) => ({
      value: answer.toString(),
      title: answer.toString(),
    }));
  }, [prismDirection]);

  return (
    <Controller
      name={id}
      control={formData.control}
      defaultValue={""}
      rules={{
        validate: (value, allValues) => {
          if (!disabled) {
            if (
              (!value && allValues[idHorizontalVertical]) ||
              (required && !disabled && value === "")
            ) {
              return "required";
            }
          }
          return true;
        },
      }}
      render={({ field, fieldState }) => (
        <SelectSimple
          id={id}
          onChange={(value, isDefault) => {
            field.onChange(value);
          }}
          defaultValue={""}
          ariaLabel={`${translate("survey.prism_basedir")}${
            !disabled && (valueHorizontalVertical || required)
              ? " - required field"
              : ""
          }${
            errorInfo?.errorForFields?.includes(id)
              ? ` - ${errorInfo.title}`
              : ""
          }`}
          className={style.InputField}
          options={options}
          selection={field.value}
          isError={fieldState.error && showErrors}
          disabled={disabled}
          middleValueMenu={options?.[0]?.value}
          placeholder={translate("survey.placeholder")}
          keepPlaceholder={true}
          ariaErrormessage={"Please fill this field"}
        />
      )}
    />
  );
};

const HorizontalVerticalComponent = ({
  formData,
  id,
  idBaseDir,
  prismDirection,
  showErrors = false,
  required = false,
  disabled,
  translate,
  errorInfo,
}) => {
  const options = useMemo(() => {
    return generateInputValues(prismDirection).map((answer) => ({
      value: answer.toString(),
      title: answer.toString(),
    }));
  }, [prismDirection]);

  return (
    <Controller
      name={id}
      control={formData.control}
      defaultValue={""}
      rules={{
        required: required && !disabled,
        validate: (value, allValues) => {
          if (!disabled) {
            if (
              (!value && allValues[idBaseDir]) ||
              (required && !disabled && value === "")
            ) {
              return "required";
            }
          }
          return true;
        },
      }}
      render={({ field, fieldState }) => (
        <SelectSimple
          id={id}
          onChange={(value, isDefault) => {
            field.onChange(value);
          }}
          defaultValue={""}
          ariaLabel={`${translate(
            prismDirection === "prism_h"
              ? "survey.prism_horizontal"
              : "survey.prism_vertical"
          )} ${required && !disabled ? " - required field" : ""}${
            errorInfo?.errorForFields?.includes(id)
              ? ` - ${errorInfo.title}`
              : ""
          }`}
          className={style.InputField}
          options={options}
          selection={field.value}
          isError={fieldState.error && showErrors}
          disabled={disabled}
          middleValueMenu={options?.[0]?.value}
          placeholder={translate("survey.placeholder")}
          keepPlaceholder={true}
          ariaErrormessage={"Please fill this field"}
        />
      )}
    />
  );
};

interface CLInputComponentProps {
  className?: string;
  formData: any;
  ids: CLFormData;
  sameForBothEyes?: boolean;
  placeholder?: string;
  internalPlaceholder?: string;
  showCLBrandErrors?: boolean;
  ariaErrormessage?: string;
}

const CLInputComponent: React.FC<CLInputComponentProps> = ({
  formData,
  ids,
  sameForBothEyes = false,
  placeholder = "",
  internalPlaceholder = "",
  showCLBrandErrors = false,
  ariaErrormessage = "",
}) => {
  const translate = useTranslation();

  return (
    <>
      <div className={style.ClBrandInputs}>
        {sameForBothEyes ? (
          <Controller
            name={ids.lensBrandRight}
            control={formData.control}
            defaultValue={null}
            rules={{
              validate: (value) => {
                if (["", null].includes(value)) {
                  return ids.lensBrandRight + "Required";
                }
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <Input
                  id={ids.lensBrandRight}
                  className={`${style.InputField}`}
                  placeholder={placeholder}
                  internalPlaceholder={internalPlaceholder}
                  value={field.value}
                  onChange={field.onChange}
                  isError={fieldState.error && showCLBrandErrors}
                  type="text"
                  ariaErrormessage={ariaErrormessage}
                />
              );
            }}
          />
        ) : (
          <>
            <div className={style.brandInputLabel}>
              {translate("survey.right_eye")}
            </div>
            <Controller
              name={ids.lensBrandRight}
              control={formData.control}
              defaultValue={null}
              rules={{
                validate: (value) => {
                  if (["", null].includes(value)) {
                    return ids.lensBrandRight + "Required";
                  }
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <Input
                    id={ids.lensBrandRight}
                    className={`${style.InputField}`}
                    placeholder={placeholder}
                    internalPlaceholder={internalPlaceholder}
                    value={field.value}
                    onChange={field.onChange}
                    isError={fieldState.error && showCLBrandErrors}
                    type="text"
                    ariaErrormessage={ariaErrormessage}
                  />
                );
              }}
            />
            <div className={style.brandInputLabel}>
              {translate("survey.left_eye")}
            </div>
            <Controller
              name={ids.lensBrandLeft}
              control={formData.control}
              defaultValue={null}
              rules={{
                validate: (value) => {
                  if (["", null].includes(value)) {
                    return ids.lensBrandLeft + "Required";
                  }
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <Input
                    id={ids.lensBrandLeft}
                    className={`${style.InputField}`}
                    placeholder={placeholder}
                    internalPlaceholder={internalPlaceholder}
                    value={field.value}
                    onChange={field.onChange}
                    isError={fieldState.error && showCLBrandErrors}
                    type="text"
                    ariaErrormessage={ariaErrormessage}
                  />
                );
              }}
            />
          </>
        )}
      </div>
      {showCLBrandErrors &&
        !sameForBothEyes &&
        (formData.formState.errors[ids.lensBrandRight] ||
          formData.formState.errors[ids.lensBrandLeft]) && (
          <InfoBanner
            className={style.brandErrorBanner}
            label={translate("survey.q9_CL_brand_error_title")}
            description={translate("survey.q9_CL_brand_error_descr")}
            variant={"error"}
          />
        )}
    </>
  );
};

export default PrescriptionForm;
