import React, { MouseEvent, ReactNode } from "react";
import style from "./style.module.scss";
import LazyImage from "@components/common/LazyImage/LazyImage";
import Button from "@components/common/Button/component";
import { useTranslation } from "@hooks/useTranslation";
import Chip from "@components/common/Chip/component";
import { clsx } from "@utility/utility";

type ButtonSurveyProps = {
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  displayNone?: boolean;
  icon?: string;
  labelKey?: string;
  translatedLabel?: string;
  values?: (string | { label: string; value: string })[];
  showOnDesktop?: boolean;
};

const ButtonSurvey = ({
  onClick = () => {},
  className = "",
  displayNone = false,
  icon = "",
  labelKey = "",
  translatedLabel = "",
  values = [],
  showOnDesktop = false,
}: ButtonSurveyProps) => {
  const translate = useTranslation();

  return (
    <Button
      className={clsx(style.buttonSurvey, {
        [className]: !!className,
        [style.none]: displayNone,
        [style.showOnDesktop]: showOnDesktop,
      })}
      onClick={onClick}
      variant="transparent"
    >
      <div className={style.buttonSurveyRow}>
        <div className={style.placeholderSection}>
          {icon && (
            <LazyImage
              className={style.placeholderIcon}
              src={icon}
              alt={translate(labelKey)}
              width="20px"
              height="20px"
            />
          )}
          <p className={style.placeholder}>
            {translatedLabel ? translatedLabel : translate(labelKey)}
          </p>
        </div>

        <LazyImage
          className={style.addIcon}
          src={
            window.rxrEnv?.ASSETS_PATH +
            "/icons/" +
            (values?.length > 0 ? "edit.svg" : "plus-icon.svg")
          }
          alt={translate(
            values?.length > 0 ? "wcag.wcag_edit" : "wcag.wcag_plus_icon"
          )}
          width="20px"
          height="20px"
        />
      </div>

      {values?.length > 0 && (
        <div
          className={`${style.selectedFileContainer} ${
            values.some((value) => typeof value === "object")
              ? style.FlexColumn
              : ""
          }`}
        >
          {values.map((value, index) =>
            typeof value === "string" ? (
              <Chip key={value} label={value} />
            ) : (
              <div key={index} className={style.ObjectChipContainer}>
                <p className={style.ExternalChipLabel}>{value.label}</p>
                <Chip label={value.value} />
              </div>
            )
          )}
        </div>
      )}
    </Button>
  );
};

export default ButtonSurvey;
