import React, { memo, useEffect, useState, useRef } from "react";
import style from "./style.module.scss";
import { createPortal } from "react-dom";
import { useTranslation } from "@hooks/useTranslation";
import LazyImage from "@components/common/LazyImage/LazyImage";
import IconCircleModal from "@components/common/IconCircleModal/component";
import { SELECTOR_RXR } from "@utility/utility";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import throttle from "lodash.throttle";

type ModalDetectOrientationProps = {
  className?: string;
};

const ModalDetectOrientation = memo(
  ({ className = "" }: ModalDetectOrientationProps) => {
    const translate = useTranslation();
    const breakpoint = useBreakpointBoolean();
    const [message, setMessage] = useState<string>("");
    const [initialPosition, setInitialPosition] = useState<{
      alpha: number;
      beta: number;
      gamma: number;
    } | null>(null);
    const [coordinates, setCoordinates] = useState<{
      alpha: number;
      beta: number;
      gamma: number;
    }>({
      alpha: null,
      beta: null,
      gamma: null,
    });

    // Stato precedente per verificare la persistenza del problema
    const prevErrorRef = useRef<string | null>(null);

    function angleDifference360(angle1, angle2) {
      let diff = Math.abs((angle2 - angle1) % 360);
      return diff > 180 ? 360 - diff : diff;
    }

    function angleDifference180(angle1, angle2) {
      let diff = (angle2 - angle1) % 360;
      if (diff > 180) {
        diff -= 360;
      } else if (diff < -180) {
        diff += 360;
      }
      return Math.abs(diff);
    }

    useEffect(() => {
      // Orientation and motion data explained --> https://developer.mozilla.org/en-US/docs/Web/API/Device_orientation_events/Orientation_and_motion_data_explained
      const handleOrientation = throttle((event: DeviceOrientationEvent) => {
        const { alpha, beta, gamma } = event;

        // Se i valori alpha, beta o gamma sono nulli, non fare nulla
        if (alpha === null || beta === null || gamma === null) return;

        setCoordinates({
          alpha: Math.round(alpha),
          beta: Math.round(beta),
          gamma: Math.round(gamma),
        });

        // Imposta initialPosition solo se non è già stato impostato
        if (initialPosition === null) {
          // Se tutti i valori sono 0, non settare la posizione iniziale
          if (alpha === 0 && beta === 0 && gamma === 0) {
            return; // Non fare nulla se sono tutti a zero
          }

          setInitialPosition({
            alpha: Math.round(alpha),
            beta: Math.round(beta),
            gamma: Math.round(gamma),
          });
          return;
        }

        const baseTolerance = 60; // Tolleranza base di 45 gradi per alpha
        const gammaVariation = Math.abs(gamma - initialPosition.gamma); // Variazione assoluta di gamma
        const gammaFactor = gammaVariation / 90; // Factor che aumenta la tolleranza in base alla variazione di gamma (max 1 se gamma varia di 90°)
        const alphaTolerance = baseTolerance * (1 + gammaFactor); // Tolleranza finale per alpha

        const alphaError =
          angleDifference360(initialPosition.alpha, alpha) > alphaTolerance
            ? "ERROR: ALPHA"
            : null;
        const betaError = beta < 45 || beta > 135 ? "ERROR: BETA" : null;
        const gammaError =
          angleDifference180(0, gamma) > 60 && (beta < 80 || beta > 120)
            ? "ERROR: GAMMA"
            : null;

        const currentError = alphaError || betaError || gammaError || "";

        // Controllo che l'errore sia presente per due misurazioni consecutive
        if (prevErrorRef.current === currentError) {
          setMessage(currentError);
        }

        // Aggiorno il valore precedente per la prossima iterazione
        prevErrorRef.current = currentError;
      }, 300);

      window.addEventListener("deviceorientation", handleOrientation);

      return () => {
        window.removeEventListener("deviceorientation", handleOrientation);
      };
    }, [initialPosition]);

    return message
      ? createPortal(
          <div className={`${style.modal} ${className}`}>
            <div
              className={`${style.modalContent} ${
                coordinates?.beta > -135 && coordinates?.beta < -45
                  ? style.flipped_upside_down
                  : ""
              }
            ${breakpoint.isMobileLandscape ? style.flipped_horizontal : ""}`}
            >
              <IconCircleModal
                className={style.iconCircleRing}
                classNameCircle={style.iconCircleCircle}
              >
                <LazyImage
                  className={style.rotateDeviceIcon}
                  src={
                    window.rxrEnv?.ASSETS_PATH +
                    "/icons/keep-phone-forward-vertical.svg"
                  }
                  alt={"Keep phone forward"}
                  width={"64px"}
                  height={"64px"}
                />
              </IconCircleModal>
              <p className={style.title}>
                {translate("detect_orientation_modal.title")}
              </p>
              <p className={style.message}>
                {translate("detect_orientation_modal.description")}
              </p>
              <p className={style.spacer}></p>

              {/* Debug Info */}
              {/* <p style={{color: 'red', fontSize: 'bold'}} className={style.message}>{message}</p>
                <p className={style.message}>START ALPHA: {initialPosition?.alpha}</p>
                <p className={style.message}>START BETA: {initialPosition?.beta}</p>
                <p className={style.message}>START GAMMA: {initialPosition?.gamma}</p>
                <p className={style.message}>ALPHA: {coordinates?.alpha}</p>
                <p className={style.message}>BETA: {coordinates?.beta}</p>
                <p className={style.message}>GAMMA: {coordinates?.gamma}</p> */}

              <p className={style.bottomRestartLink}>
                <span>
                  {translate("detect_orientation_modal.restart_link_1")}
                </span>
                <span
                  className={style.restart_link}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => window.location.reload()}
                >
                  {translate("detect_orientation_modal.restart_link_2")}
                </span>
              </p>
            </div>
          </div>,
          document.querySelector(SELECTOR_RXR)
        )
      : null;
  }
);

export default ModalDetectOrientation;
